import React, { useEffect, useRef, useState } from "react";
import styles from "./MediaPlayer.module.css";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAudioDataSuccess } from "../../redux/sinkReducer/action";
import ReactDRMPlayer from "@gumlet/react-drm-player";
import crypto from "crypto-js";
import { Col, Modal, Row } from "react-bootstrap";
import "./checkbox.css";
import { Controls } from "./Controls";
import { generateTokenHandler, gumletCollectionId } from "../../utils/json-web";

export const WatchPartyMediaPlayer = ({
  getToast,
  asset_id,
  // handleSelectEpisode,
  getDeviceType,
}) => {
  console.log("asset_id: ", asset_id);
  const { t } = useTranslation();
  const [isSeries, setIsSeries] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [playerSrc, setPlayerSrc] = useState(null);
  const [assetId, setAssestId] = useState(asset_id);
  const [transactionId, setTransactionId] = useState("");
  const [show, setShow] = useState(false);

  // store the payment status
  const [paymentStatus, setPaymentStatus] = useState(null);

  // save episodesList here
  const episodeNumber = sessionStorage.getItem("episodeNumber");
  const [episodesList, setEpisodesList] = useState([]);
  const viewerId = window.viewerId || localStorage.getItem("viewerId");
  const paymentDetails = JSON.parse(sessionStorage.getItem("paymentDetails"));
  console.log("paymentDetails: ", paymentDetails);
  const location = JSON.parse(localStorage.getItem("location")) || {
    latitude: "20.59",
    longitude: "78.96",
  };
  console.log("location: ", typeof location);
  console.log("viewerId: ", viewerId);
  // episode selected
  const [selectedEpisode, setSelectedEpisode] = useState(
    parseInt(episodeNumber) ? parseInt(episodeNumber) : 1
  );
  const videoId = window.videoDetails || sessionStorage.getItem("videoDetails");
  const [widevineLicenseURI, setWideWineLicenseURI] = useState("");
  console.log("videoId: ", videoId);
  // token
  const token = localStorage.getItem("authUser");
  console.log("token: ", token);
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const primaryDevice = window.primaryDevice;
  // this way we will get the viewership Id
  const viewerShipId =
    window.viewerShipId || sessionStorage.getItem("viewerShipId");
  const [counter, setCounter] = useState(0);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  const videoRef = useRef(null);

  const { audioUrl, subTitle } = useSelector((store) => store.sinkReducer);
  const dispatch = useDispatch();

  const [seasonNumber, setSeasonNumber] = useState(
    parseInt(sessionStorage.getItem("seasonNumber"))
      ? parseInt(sessionStorage.getItem("seasonNumber"))
      : null
  );
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // flags array
  const flags = [
    { name: "buffering", category: "technical", label: "Buffering" },
    { name: "videoQuality", category: "technical", label: "Video Quality" },
    { name: "audioQuality", category: "technical", label: "Audio Quality" },
    { name: "subtitle", category: "technical", label: "Subtitle" },
    { name: "antiReligious", category: "moderation", label: "Anti Religious" },
    { name: "antiNational", category: "moderation", label: "Anti National" },
    {
      name: "incitingViolence",
      category: "moderation",
      label: "Inciting Violence",
    },
    { name: "tobacco", category: "auto_moderation", label: "Tobacco" },
    { name: "drugs", category: "auto_moderation", label: "Drugs" },
    { name: "weapons", category: "auto_moderation", label: "Weapons" },
    { name: "alcohols", category: "auto_moderation", label: "Alcohols" },
    { name: "gore", category: "auto_moderation", label: "Gore" },
    { name: "nudity", category: "auto_moderation", label: "Nudity" },
  ];

  // Custom function to parse non-standard JSON-like string
  function parseCustomJsonString(inputString = "") {
    if (!inputString || typeof inputString != "string") {
      return;
    }
    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      console.log("parts: ", parts);

      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"/g, ""); // Remove double quotes around values

      result[key] = value;
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const handleAbortVideo = async () => {
    console.log("Page Unmount");
    //
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
      slug: slug,
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    // getting watchTime
    const watchTime = document.getElementById("myVideo")?.currentTime;
    console.log("watchTime: ", watchTime);
    //
    let data;
    if (isSeries) {
      data = {
        viewerId,
        viewerShipId,
        transactionId,
        videoId,
        watchTime,
        // will add transcationId
        seasonNumber,
        episodeNumber: selectedEpisode,
        isSeries,
      };
    } else {
      data = {
        viewerId,
        viewerShipId,
        transactionId,
        videoId,
        isSeries,
        watchTime,
        // will add transcationId
      };
    }

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `storeVideoAbortInfo`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
        if (res.data.status && res.data.status_code == 200) {
          console.log("Successfull Response", res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        }
      });
  };
  const handleStartVideoInfo = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      slug: slug,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    let data;
    const watchTime = document.getElementById("myVideo")?.currentTime;
    console.log("watchTime: ", watchTime);
    if (isSeries) {
      data = {
        viewerId,
        viewerShipId,
        location: parseCustomJsonString(location) || location,

        transactionId,
        videoId,
        isSeries,
        // seasonNumber: parseInt(seasonNumber),
        // episodeNumber: selectedEpisode,
        seasonNumber: parseInt(seasonNumber),
        episodeNumber: parseInt(selectedEpisode),
        startTime:
          new Date() +
          document.getElementById("myVideo")?.currentTime?.toFixed(),
        watchTime: watchTime,
        // will add transcationId
      };
    } else {
      data = {
        viewerId,
        isSeries,
        viewerShipId,
        location: parseCustomJsonString(location) || location,
        transactionId,
        videoId,
        startTime:
          new Date() +
          document.getElementById("myVideo")?.currentTime?.toFixed(),
        watchTime: watchTime,
        // will add transcationId
      };
    }

    console.log("data----", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `storeVideoStartInfo`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
        console.log("res: ", res.data.message);
        if (res.data.status && res.data.status_code == 200) {
          console.log("Successfull Response", res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        }
      });
  };

  const getWatchTime = async (transactionId) => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      slug: slug,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    let data;
    // const watchTime = document.getElementById("myVideo").currentTime;
    // console.log("watchTime: ", watchTime);
    if (isSeries) {
      data = {
        viewerId,
        viewerShipId,
        transactionId,
        videoId,
        isSeries,
        seasonNumber: parseInt(seasonNumber),
        episodeNumber: selectedEpisode,

        // will add transcationId
      };
    } else {
      data = {
        viewerId,

        viewerShipId,
        isSeries,
        transactionId,
        videoId,

        // will add transcationId
      };
    }

    console.log("data----", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getWatchTime`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
        console.log("res: ", res.data.message);
        if (res.data.status && res.data.status_code == 200) {
          console.log("Successfull Response", res.data);
          document.getElementById("myVideo").currentTime =
            res?.data?.watchTime || 0;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        }
      });
  };
  // function for getting the assset it from videoDetails
  const getAssetDetailsById = async (data) => {
    let slug = await generateTokenHandler();
    console.log("getAssetIdDetailsById --------");
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
      slug: slug,
    };
    console.log(token);
    console.log(headers.devicetype);
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    // code for fetching details via assetsId

    const config = {
      method: "post",
      url: movieBaseUrl + "getAssetIdByVideoId",
      headers: headers,
      data,
    };
    axios
      .request(config)
      .then((res) => {
        // set the asset Id here
        if (res.data.status) {
          const assetId = res?.data?.data?.assetId;

          console.log("assetId: ", assetId);
          console.log(assetId);
          sessionStorage.setItem("assetId", assetId);
          setAssestId(assetId);
          const transactionId = res?.data?.data?.transactionId;
          console.log("transactionId: ", transactionId);
          getWatchTime(transactionId);
          // generateAuthToken(assetId);
          setTransactionId(transactionId);
          const audioFileUrl = res?.data?.data?.audioUrl;
          const subTitleFileUrl =
            res?.data?.data?.subTitle && res.data.data?.subTitle[0]?.file;
          // send details to save in reducer

          const reducerDetails = {
            audioUrl: audioFileUrl,
            viewerShipId,
            assetId,
            viewerId,
            subTitle: subTitleFileUrl,
          };
          dispatch(getAudioDataSuccess(reducerDetails));
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  // get all the episode list
  const getEpisodes = async () => {
    let slug = await generateTokenHandler();
    console.log("getE");
    setCounter(counter + 1);
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType ?? getDeviceType(),
      slug: slug,
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getVideoDetailsById/${videoId}`,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.data) {
          const data = res?.data?.data;
          setIsSeries(data?.isSeries);

          var assetsData = {
            videoId: videoId,
          };
          if (data?.isSeries) {
            // const currentSeason = data?.seasons.length - 1;
            const season = data?.seasons ?.find((item) => item?.seasonNumber === seasonNumber);
            // setSeasonNumber(season?.seasonNumber);
            // assetsData.seasonNumber = season?.seasonNumber;
            assetsData.seasonNumber = parseInt(seasonNumber);
            assetsData.episodeNumber = parseInt(selectedEpisode);
            setEpisodesList(season?.episodes);
            getAssetDetailsById(assetsData);
          } else {
            getAssetDetailsById(assetsData);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  // handle selcted epissode
  const handleSelectEpisode = (episodeNumber) => {
    setSelectedEpisode(episodeNumber);
    sessionStorage.setItem("episodeNumber", episodeNumber);
    // call the get AssetDetials here
  };
  useEffect(() => {
    getEpisodes();
    // get Payment Status for user
    getPaymentStatus();
  }, [selectedEpisode, navigate]);

  useEffect(() => {
    console.log("AssestId changes", assetId);
    const generateAuthToken = async () => {
      const widevineProxy =
        `https://widevine.gumlet.com/licence/${gumletCollectionId}`;
      const proxySecret = "e5b2e50658aaa3b66bd95d77bc556564";
      const proxyUrl = `${widevineProxy}/${assetId}`;
      const tokenLifetime = 30; // seconds

      const queryparams = {
        expires: Math.round(Date.now() / 1000) + tokenLifetime,
      };

      const stringForTokenGeneration = `${proxyUrl.slice(
        35
      )}?${new URLSearchParams(queryparams).toString()}`;

      const signature = crypto
        .HmacSHA1(stringForTokenGeneration, proxySecret)
        .toString(crypto.enc.Hex);

      const authToken = signature;
      const signedProxyUrl = `${proxyUrl}?${new URLSearchParams({
        ...queryparams,
        token: authToken,
      }).toString()}`;

      console.log("Auth Token:", authToken);
      console.log("Signed Licence Server URL:", signedProxyUrl);
      setWideWineLicenseURI(signedProxyUrl);
      // Set the playerSrc with the signed URL
      setPlayerSrc(
        `https://video.gumlet.io/${gumletCollectionId}/${assetId}/main.m3u8`
      );

      // Optionally, if you want to set the video source directly in the video element
      const videoElement = document.getElementById("myVideo");
      if (videoElement) {
        videoElement.addEventListener("play", () => handleStartVideoInfo());
        videoElement.addEventListener("pause", () => handleStartVideoInfo());
        videoElement.addEventListener("abort", () => console.error("Abort"));
      }

      // videoElement.src = `https://video.gumlet.io/658414dd064278d81eef108a/${assetId}/main.m3u8`;
      // videoElement.src = signedProxyUrl;
    };

    generateAuthToken();

    window.handleAbortVideo = handleAbortVideo;

    // const videoElement = document.getElementById("myVideo");

    window.flutterMessageHandler = function (message) {
      console.log("Message from Flutter: ", message);

      if (message === "play") {
        document.getElementById("myVideo")?.play();
      } else if (message === "pause") {
        document.getElementById("myVideo")?.pause();
      } else if (message === "forward") {
        document.getElementById("myVideo").currentTime += 5; // Move forward by 10 seconds
      } else if (message === "backward") {
        document.getElementById("myVideo").currentTime -= 5; // Move backward by 10 seconds
      }
      // Add more controls as needed
    };
    return () => {
      // const videoElement = document.getElementById("myVideo");
      // if (videoElement) {
      //   videoElement.removeEventListener("play");
      //   videoElement.removeEventListener("abort");
      // }
    };
  }, [assetId, selectedEpisode]);

  const handleClose = () => {
    setShow(false);
  };

  // submit report
  const handleSubmit = async () => {
    let slug = await generateTokenHandler();
    console.log("report submited");
    console.log(selectedIssues);
    if (selectedIssues?.length == 0) {
      getToast(t("Please select atleast one issue."), "top-right", "warn");
      return;
    }
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType ?? getDeviceType(),
      slug: slug,
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    let currentTimeInSeconds = 1;
    if (videoRef.current) {
      currentTimeInSeconds = videoRef.current.currentTime;
      console.log("Current Time (seconds):", currentTimeInSeconds);
      // Do something with currentTimeInSeconds
    }
    const data = {
      videoId,
      issueCategory: selectedCategory,
      issue: selectedIssues,
      transactionId,
      isSeries,
      time: currentTimeInSeconds,
    };
    console.log("data: ", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `report`,
      headers: headers,
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        setShow(false);
      })
      .catch((err) => {
        setShow(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };
  const handleCheckboxChange = (issueName, isChecked, category) => {
    if (isChecked) {
      setSelectedIssues([
        ...selectedIssues.filter((name) =>
          flags.find((flag) => flag.category === category && flag.name === name)
        ),
        issueName,
      ]);
      setSelectedCategory(category);
    } else {
      setSelectedIssues(selectedIssues.filter((name) => name !== issueName));
      setSelectedCategory(null);
    }
  };

  // Get Payment Status For Video

  const getPaymentStatus = async () => {
    console.log("get Payment status--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("isSeries", isSeries);
    let series = JSON.parse(sessionStorage.getItem("isSeries"));
    console.log("season Number Demo ", seasonNumber);

    let url =
      movieBaseUrl +
      "getPaymentStatusForVideo_v3" +
      `?videoId=${videoId}&isSeries=${series ? "true" : "false"}`;
    if (series) {
      url =
        movieBaseUrl +
        "getPaymentStatusForVideo_v3" +
        `?videoId=${videoId}&isSeries=${
          series ? "true" : "false"
        }&seasonNumber=${seasonNumber}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      // movieBaseUrl + "getPaymentStatusForVideo_v3" + `?videoId=${video_id}&isSeries=${series ?'true' : 'false'} ${series && "&seasonNumber=" + seasonNumber}`,
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          setPaymentStatus(res.data.data);
          // getToast(res.data.message, "top-right", "success");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  return (
    <div>
      <div id="player" style={{ width: "100%" }}></div>
      <div className={styles.media_container}>
        <div className={styles.media_frame}>
          {playerSrc && (
            <ReactDRMPlayer
              src={playerSrc}
              controls
              preload="none"
              autoPlay={false}
              id="myVideo"
              onPlay={handleStartVideoInfo}
              // onPlay={handleStartVideoInfo}
              onAbort={handleAbortVideo}
              ref={videoRef}
              widevineLicenseURI={widevineLicenseURI}
            />
          )}
          <div>{/* <Controls id="myVideo" /> */}</div>
          <div className={styles.report_btn_container}>
            <button onClick={() => setShow(true)}>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                // stroke="#fff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 1C3.44772 1 3 1.44772 3 2V22C3 22.5523 3.44772 23 4 23C4.55228 23 5 22.5523 5 22V13.5983C5.46602 13.3663 6.20273 13.0429 6.99251 12.8455C8.40911 12.4914 9.54598 12.6221 10.168 13.555C11.329 15.2964 13.5462 15.4498 15.2526 15.2798C17.0533 15.1004 18.8348 14.5107 19.7354 14.1776C20.5267 13.885 21 13.1336 21 12.3408V5.72337C21 4.17197 19.3578 3.26624 18.0489 3.85981C16.9875 4.34118 15.5774 4.87875 14.3031 5.0563C12.9699 5.24207 12.1956 4.9907 11.832 4.44544C10.5201 2.47763 8.27558 2.24466 6.66694 2.37871C6.0494 2.43018 5.47559 2.53816 5 2.65249V2C5 1.44772 4.55228 1 4 1ZM5 4.72107V11.4047C5.44083 11.2247 5.95616 11.043 6.50747 10.9052C8.09087 10.5094 10.454 10.3787 11.832 12.4455C12.3106 13.1634 13.4135 13.4531 15.0543 13.2897C16.5758 13.1381 18.1422 12.6321 19 12.3172V5.72337C19 5.67794 18.9081 5.66623 18.875 5.68126C17.7575 6.18804 16.1396 6.81972 14.5791 7.03716C13.0776 7.24639 11.2104 7.1185 10.168 5.55488C9.47989 4.52284 8.2244 4.25586 6.83304 4.3718C6.12405 4.43089 5.46427 4.58626 5 4.72107Z"
                    // fill="#fff"
                  ></path>{" "}
                </g>
              </svg>
              Report
            </button>
          </div>
        </div>
        {/* if it is a series then show here the list of episodes */}
        {isSeries && paymentStatus ? (
          <div className={styles.episodes_list}>
            {episodesList
              ?.filter((items) =>
                paymentStatus?.docs?.some(
                  (item) =>
                    item?.deviceType === getDeviceType() &&
                    (item?.episodeNumber === items?.episodeNumber ||
                      item?.episodeNumber === null)
                )
              )
              ?.sort((a, b) => a.episodeNumber - b.episodeNumber) // Sort episodes by episode number
              .map((el, i) => {
                return (
                  <div
                    key={i}
                    className={
                      selectedEpisode == el.episodeNumber
                        ? styles.selected_episode
                        : ""
                    }
                    onClick={() => handleSelectEpisode(el.episodeNumber)}
                  >
                    <div>
                      <img src={el?.thumbnail?.file} alt="" />
                    </div>
                    <div>
                      <h3>{el?.title}</h3>
                      <div className={styles.episodes_details_header}>
                        <span>
                          {" "}
                          {Math.floor((el.duration || 0) / 60) +
                            "." +
                            Math.floor((el.duration || 0) % 60) +
                            " " +
                            "min"}
                        </span>

                        <span>Episode - {el?.episodeNumber}</span>
                      </div>
                      <p>{el?.synopsis}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className={styles.price_modal_container}>
          <Row>
            <Col>
              <h3>Report Video</h3>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleClose()}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#50e4ad"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#50e4ad"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
          <div className={styles.flag_container}>
            {flags?.map((el, i) => {
              return (
                <div class="checkbox-wrapper-1">
                  <input
                    id={el?.name}
                    class="substituted"
                    type="checkbox"
                    aria-hidden="true"
                    value={el.name}
                    checked={selectedIssues.includes(el.name)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        el.name,
                        e.target.checked,
                        el.category
                      )
                    }
                    disabled={
                      selectedCategory !== null &&
                      selectedCategory !== el.category
                    }
                  />
                  <label for={el?.name}>
                    <span className={styles.capitalize}>{el.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
          <div className={styles.report_btn_container}>
            <button
              variant="primary"
              onClick={handleSubmit}
              className={styles.report_btn}
            >
              Submit
            </button>
          </div>
        </Modal.Header>
      </Modal>
    </div>
  );
};
