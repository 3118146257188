import * as types from "./actionType";
const intialData = {
  data: [],
  isLoading: false,
  isError: false,
  totalCount: 0,
  walletBalance: 0,
};

export const reducer = (state = intialData, { type, payload }) => {
  //console.log(type, payload);
  switch (type) {
    case types.GET_DATA_REQ:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_DATA_SUCC:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        totalCount: payload.dataLength,
      };
    case types.GET_DATA_ERR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case types.GET_WALLET_DETAILS_SUCC:
      return {
        ...state,
        walletBalance: payload?.toFixed(2),
      };
    case types.GET_WALLET_DETAILS_ERR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};
