import React, { useEffect } from "react";
import styles from "./BuyTickets.module.css";
import { useTranslation } from "react-i18next";
import { AddBuddy } from "../../components/Add Buddy/AddBuddy";
import { ConnectHeadphones } from "../../components/Connect Headphones/ConnectHeadphones";
import { WatchScreen } from "../../components/Watch/WatchScreen";
import poster from "../../assets/new-images/Posters/bg_1.webp";
import star from "../../assets/new-images/Home/star.png";
import { useNavigate, useParams } from "react-router-dom";

export const BuyTickets = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { step_slug } = useParams();
  //   get screen
  const getScreen = (key) => {
    switch (key) {
      case "add_buddy":
        return <AddBuddy />;

      case "connect_headphones":
        return <ConnectHeadphones />;

      case "watch":
        return <WatchScreen />;

      default:
        return <AddBuddy />;
    }
  };

  // navigate to the screen
  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    //
  }, [step_slug]);

  return (
    <div className={styles.buy_tickets_container}>
      {/* content detials */}
      <div className={styles.details_header_container}>
        <div className={styles.details_header}>
          <img src={poster} alt="" />
          <div>
            <h1>Freedom</h1>
            <div>
              <h6>U/A</h6>
              <h6>Action/ Thriller</h6>
              <h6>132 min</h6>
            </div>
            <div className={styles.rating_box}>
              4.5
              <img src={star} />
            </div>
          </div>
        </div>
      </div>

      {/* step form */}
      <div className={styles.buy_ticket_steps}>
        <div onClick={() => handleNavigate("/buy_tickets/add_buddy")}>
          <div className={styles.selected_page}>1</div>
          <div>{t("Add your buddy & pay")}</div>
        </div>
        <div
          className={`${styles.line_for_steps} ${
            step_slug == "watch" || step_slug == "connect_headphones"
              ? styles.selected_page
              : ""
          }`}
        ></div>
        <div onClick={() => handleNavigate("/buy_tickets/connect_headphones")}>
          <div
            className={
              step_slug == "watch" || step_slug == "connect_headphones"
                ? styles.selected_page
                : ""
            }
          >
            2
          </div>
          <div>{t("Connect headphones")}</div>
        </div>
        <div
          className={`${styles.line_for_steps} ${
            step_slug == "watch" ? styles.selected_page : ""
          }`}
        ></div>
        <div onClick={() => handleNavigate("/buy_tickets/watch")}>
          <div className={step_slug == "watch" ? styles.selected_page : ""}>
            3
          </div>
          <div>{t("Get ready to watch")}</div>
        </div>
      </div>

      {/* current screen */}
      <div className={styles.selected_screen}>{getScreen(step_slug)}</div>
    </div>
  );
};
