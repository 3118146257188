import * as types from "./actionType";
export const getDataReq = () => {
  return {
    type: types.GET_DATA_REQ,
  };
};

export const getDataSuccess = (pa) => {
  return {
    type: types.GET_DATA_SUCC,
    payload: pa,
  };
};
export const getDataError = () => {
  return {
    type: types.GET_DATA_ERR,
  };
};
export const getDataReqById = () => {
  return {
    type: types.GET_DATA_REQ_BY_ID,
  };
};

export const getDataSuccessById = (pa) => {
  return {
    type: types.GET_DATA_SUCC_BY_ID,
    payload: pa,
  };
};
export const getDataErrorById = () => {
  return {
    type: types.GET_DATA_ERR_BY_ID,
  };
};

// export const searchMovies = (data) => {
//   let config = {
//     method: "post",
//     maxBodyLength: Infinity,
//     url: movieBaseUrl + "searchMovies.v1",
//     headers: {
//       authorization: localStorage.getItem("authUser") || "",
//     },
//     data: data,
//   };

//   return axios.request(config);
// };
// actions for getWallet

export const getWalletSucc = (payload) => {
  return {
    type: types.GET_WALLET_DETAILS_SUCC,
    payload,
  };
};

export const getErrorWallet = () => {
  return {
    type: types.GET_WALLET_DETAILS_ERR,
  };
};
