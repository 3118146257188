import { useState, useEffect } from 'react';

export default function useAuthListener() {
  const [user, setUser] = useState(localStorage.getItem('authUser'));

  useEffect(() => {
    const authUser = localStorage.getItem('authUser');
    setUser(authUser);
  }, []);
  
  return user ;
}