import React, { useEffect, useState } from "react";
import styles from "./WatchHistory.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import no_result from "../../assets/images/no_result_found.png";
import { WatchItem } from "./WatchItem";
import { generateTokenHandler } from "../../utils/json-web";

export const WatchHistory = ({ getToast, getDeviceType }) => {
  const [contentList, setContentList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // getting token
  const authUser = localStorage.getItem("authUser");

  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const getMovieRatingList = async () => {
    let slug = await generateTokenHandler();

    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log("headers", headers);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getRatingMovieList`,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.status && res?.data?.response_code == 200) {
          setContentList(res?.data?.data);
        } else if (res?.data?.response_code == 422) {
          setContentList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  useEffect(() => {
    // get the movie rating list
    getMovieRatingList();
  }, []);

  if (contentList?.length == 0) {
    return (
      <div className="no_result_box">
        <img src={no_result} alt="no-result-found" />
        <h3 style={{ textAlign: "center" }}>
          {t("Haven't watch anything yet? Discover some content you'll love")}
        </h3>
      </div>
    );
  }

  return (
    <div className={styles.favourrites_container}>
      <h3>{t("Watch Histroy")}</h3>

      <div className={styles.content_list_container}>
        {contentList?.map((el, i) => {
          return (
            // <NavLink to={`/details/${el?._id}`}>
            <WatchItem {...el} />
            // </NavLink>
          );
        })}
      </div>
    </div>
  );
};
