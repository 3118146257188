import React from "react";
import styles from "./ContentList.module.css";
import star from "../../assets/new-images/Home/star.png";
const ContentItem = ({ title, keyArt2, genre, rating }) => {
  return (
    <div className={styles.content_item}>
      <div>
        <img src={keyArt2?.file} alt="" />
      </div>
      <div className={styles.content_item_header}>
        <h5>{title}</h5>
      </div>
    </div>
  );
};

export default ContentItem;
