import React from "react";
import styles from "./FavouriteItem.module.css";
import { useNavigate } from "react-router-dom";
const FavouriteItem = ({
  keyArt2,
  title,
  genre,
  handleDeleteFavourite,
  videoId,
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    console.log(e.target.tagName);
    if (e.target.tagName == "rect" || e.target.tagName == "path") {
      handleDeleteFavourite(e, videoId);
    } else {
      navigate(`/details/${videoId}`);
    }
  };
  return (
    <div className={styles.content_item} onClick={handleClick}>
      <div className={styles.img_container}>
        <img src={keyArt2?.file} alt="" />

        <svg
          name="Ankita"
          fill="red"
          viewBox="-2.4 -2.4 28.80 28.80"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          // onClick={(e) => handleDeleteFavourite(e, videoId)}
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0">
            <rect
              x="-2.4"
              y="-2.4"
              width="28.80"
              height="28.80"
              rx="0"
              fill="#50e4ad"
              strokewidth="0"
            ></rect>
          </g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"></path>
          </g>
        </svg>
      </div>
      <div className={styles.content_item_header}>
        <h5>{title}</h5>
      </div>
    </div>
  );
};

export default FavouriteItem;
