import * as types from "./actionTypes";
export const getAudioDataReq = () => {
  return {
    type: types.GET_AUDIO_DATA_REQ,
  };
};

export const getAudioDataSuccess = (pa) => {
  return {
    type: types.GET_AUDIO_DATA_SUCC,
    payload: pa,
  };
};
export const getAudioDataError = () => {
  return {
    type: types.GET_AUDIO_DATA_ERR,
  };
};

export const clearAudioData = () => {
  return {
    type: types.CLEAR_DATA_ERR,
  };
};
