import React from "react";
import styles from "./ConnectHeadphones.module.css";
import { useTranslation } from "react-i18next";
export const ConnectHeadphones = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.add_buddy_container}>
      <div className={styles.scaner_container}>
        <div className={styles.social_container}></div>
      </div>

      <div>
        <div className={styles.viewer_header}>
          <h4>{t("Viewers List")}</h4>
          <button>+{t("Add viewer")}</button>
        </div>
        <div className={styles.viewer_list}>
          <div>
            <div>{t("Name")}</div>
            <div>{t("Amount")}</div>
            <div>{t("Payment Status")}</div>
          </div>
          <div>
            <div>Piryanshu</div>
            <div>23</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 38 39"
                fill="none"
              >
                <ellipse cx="19" cy="19.5" rx="19" ry="19.5" fill="#52E6C2" />
              </svg>
            </div>
          </div>
          <div>
            <div>Mimi keene</div>
            <div>203</div>
            <div>Pending</div>
          </div>
        </div>

        <div className={styles.helper_text}>
          {t(
            "Only bluetooth earphones/ headphones will be supported.Speakers are not supported."
          )}
        </div>
      </div>
    </div>
  );
};
