import React from "react";
import styles from "./Playlist.module.css";
import { useTranslation } from "react-i18next";
export const Playlist = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.playlist_container}>
      <button className={styles.playlist_btn}>
        {/* add to playlist */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="24"
          viewBox="0 0 38 24"
          fill="none"
        >
          <g clip-path="url(#clip0_23_1607)">
            <path
              d="M16.2725 2.0431C11.3388 2.0431 6.40399 2.0431 1.47033 2.0431C1.29102 2.0431 1.1117 2.04795 0.933574 2.03581C0.415941 2.00425 0.0525223 1.6607 0.00589946 1.16662C-0.0443097 0.627618 0.228254 0.202731 0.736324 0.0716237C0.935965 0.0194234 1.14876 0.00242792 1.35557 0.00242792C11.2994 0 21.242 0 31.1846 0C31.3042 0 31.4237 0 31.5421 0.0145675C32.1613 0.093475 32.5618 0.532929 32.5319 1.09256C32.502 1.65827 32.074 2.03945 31.4333 2.03945C26.3801 2.0431 21.3269 2.04188 16.2725 2.04188V2.0431Z"
              fill="white"
            />
            <path
              d="M16.2575 8.3484C21.221 8.3484 26.1858 8.3484 31.1493 8.3484C31.3131 8.3484 31.4781 8.34112 31.6407 8.3569C32.1081 8.40182 32.4739 8.76965 32.5277 9.23702C32.5803 9.69226 32.2946 10.1524 31.8582 10.3029C31.6299 10.3818 31.392 10.3939 31.1517 10.3939C21.2234 10.3927 11.2964 10.3939 1.36809 10.3915C0.43563 10.3915 -0.0317938 10.0055 0.00167897 9.28194C0.0243927 8.77572 0.374662 8.39939 0.873167 8.35569C1.03575 8.34112 1.20072 8.3484 1.3645 8.3484C6.32804 8.3484 11.2928 8.3484 16.2563 8.3484H16.2575Z"
              fill="white"
            />
            <path
              d="M12.5411 16.6385C16.2398 16.6385 19.9374 16.6385 23.6361 16.6385C23.7856 16.6385 23.935 16.6349 24.0832 16.6422C24.6642 16.6713 25.091 17.0914 25.1006 17.6413C25.1101 18.1609 24.7097 18.6052 24.155 18.6756C23.9936 18.6962 23.8274 18.6889 23.6636 18.6889C16.2518 18.6889 8.83996 18.6889 1.42812 18.6889C1.17588 18.6889 0.922442 18.7047 0.676178 18.6149C0.2231 18.4498 -0.0315326 18.0552 0.00313567 17.5563C0.035413 17.1023 0.358187 16.7381 0.819633 16.6616C0.99417 16.6325 1.17588 16.641 1.3552 16.641C5.08383 16.641 8.81127 16.641 12.5399 16.641L12.5411 16.6385Z"
              fill="white"
            />
            <path
              d="M33.0693 14.2992C33.0693 14.8904 33.092 15.4829 33.0621 16.0728C33.0406 16.4989 33.1637 16.6616 33.6084 16.6495C34.6808 16.6191 35.7543 16.6373 36.8266 16.641C37.5726 16.6434 37.9946 17.0112 38.0006 17.6498C38.0053 18.2762 37.5666 18.6804 36.8422 18.6865C35.7543 18.6962 34.6664 18.7035 33.5798 18.6829C33.1924 18.6756 33.0561 18.7945 33.0633 19.1988C33.0848 20.4115 33.0777 21.6243 33.0669 22.837C33.0609 23.5484 32.6688 23.9915 32.0759 24.0012C31.4793 24.0097 31.0669 23.5897 31.0597 22.9232C31.0478 21.6959 31.037 20.4674 31.0645 19.2401C31.0741 18.7897 30.9199 18.6719 30.4991 18.6829C29.4267 18.7083 28.3532 18.6914 27.2809 18.6901C26.8553 18.6901 26.4811 18.5712 26.2444 18.1815C25.8272 17.4956 26.2982 16.6628 27.1338 16.647C28.2659 16.6252 29.3992 16.624 30.5301 16.6483C30.9438 16.658 31.0693 16.5244 31.0609 16.1056C31.037 14.9086 31.049 13.7105 31.0538 12.5123C31.0561 11.7766 31.4435 11.3032 32.0388 11.2898C32.6413 11.2765 33.0585 11.7718 33.0657 12.5244C33.0717 13.1156 33.0657 13.7068 33.0669 14.298L33.0693 14.2992Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_23_1607">
              <rect width="38" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {t("Add to your playlist")}
      </button>
      {/* resume btn box */}
      <div className={styles.resume_btn_box}>
        <button className={styles.resume_btn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="15"
            viewBox="0 0 11 15"
            fill="none"
            
          >
            <path d="M0 15V2.37805V0L11 7.5L0 15Z" fill="#52E6C2" />
          </svg>
          {t("Resume")}
        </button>
        <button>{t("Start beginning")}</button>
      </div>
      {/* creator follow box */}
      <div className={styles.creators_folow_box}>
        <div>
          <h4>{t("Uploaded by artist")} Piryanshu</h4>
          <button className={styles.follow_btn}>{t("Subscribe")}</button>
        </div>
        <span>
          {t(
            "Get latest uploads from the artist while you support lorem ipsum lorem"
          )}
        </span>
      </div>
    </div>
  );
};
