import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../Api-config/auth";
import axios from "axios";
import { generateTokenHandler } from "../../utils/json-web";

const ForgotOTPVerification = ({ email, getDeviceType }) => {
  const [otpMessage, setOtpMessage] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const otpInputs = useRef([]);
  const navigate = useNavigate();
  const [details, setDetails] = useState({});

  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  // Custom function to parse non-standard JSON-like string
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);

  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  useEffect(() => {
    otpInputs.current[0].focus();

    const deviceDetailsFromApp = parseCustomJsonString(
      window.deviceDetails || localStorage.getItem("deviceDetails")
    );
    setDetails(deviceDetailsFromApp);
  }, []);

  const handleChange = (e, index) => {
    const { value } = e.target;

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Auto focus to the next input field
    if (value !== "" && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (e, index) => {
    // Move focus to the previous input field if the current input is empty and Backspace key is pressed
    if (
      e.key === "Backspace" &&
      otp[index] === "" &&
      otpInputs.current[index - 1]
    ) {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e, email) => {
    navigate("/change-password");
    const fullOtp = otp.join("");
    let slug = generateTokenHandler();
    console.log("device type", getDeviceType());
    var headers = {
      devicetype: getDeviceType(),
      "Content-Type": "application/json", // Adjust the content type as needed
      slug,
    };

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    var deviceDetails = {
      device: "laptop",
    };
    // if there any device details from app

    if (deviceDetailsFromApp && Object.keys(deviceDetailsFromApp).length != 0) {
      deviceDetails = deviceDetailsFromApp;
    }

    const config = {
      url: baseUrl + "api/viewerApp/forgetOtpVerification",
      method: "patch",
      headers: headers,
      data: {
        email: email,
        otp: fullOtp,
        deviceDetails: deviceDetails,
      },
    };
    // Perform validation or submit the OTP
    axios
      .request(config)
      .then((response) => {
        console.log(response);
        if (response?.data?.status == true) {
          if (response?.data?.response_code == 200) {
            localStorage.setItem("viewerId", response?.data?.userId);
            navigate("/change-password");
          }
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 404) {
            setOtpMessage(response?.data?.message);
            setTimeout(() => {
              setOtpMessage("");
            }, 3000);
          }
          if (response?.data?.response_code == 422) {
            setOtpMessage("Wrong OTP");
            setTimeout(() => {
              setOtpMessage("");
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setOtpMessage("Something went wrong");
        setTimeout(() => {
          setOtpMessage("");
        }, 3000);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="modal-body">
        <div
          className="modal-pop otp-form-body"
          style={{ boxShadow: "#1c6cbe 1px 2px 12px 3px", padding: "50px" }}
        >
          <div className="otp-form-heading">
            <h2>OTP Verfication</h2>
            <p>
              Your Epic OTP has been sent to your email. Keep an eye on your
              inbox 💌✨
            </p>
          </div>
          <div className="otp-form-box">
            {otp.map((digit, index) => (
              <input
                style={{ border: "1px solid #808080" }}
                type="text"
                className={otpMessage != "" ? "wrong-input-message" : ""}
                key={index}
                value={digit}
                inputMode="numeric"
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                ref={(input) => {
                  otpInputs.current[index] = input;
                }}
                required
              />
            ))}
          </div>
          <p className="otp-err-message">
            {otpMessage == "" ? "" : otpMessage}
          </p>
          <button className="modal-btn" onClick={(e) => handleSubmit(e, email)}>
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotOTPVerification;
