import * as types from "./actionTypes";
const intialData = {
  isLoading: false,
  viewerShipId: "",
  viewerId: "",
  audioUrl: "",
  assetId: "",
  subTitle: "",
};

export const reducer = (state = intialData, { type, payload }) => {
  switch (type) {
    case types.GET_AUDIO_DATA_REQ:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_AUDIO_DATA_SUCC:
      return {
        ...state,
        isLoading: false,
        viewerShipId: payload.viewerShipId,
        viewerId: payload.viewerId,
        audioUrl: payload.audioUrl,
        assetId: payload.assetId,
        subTitle: payload.subTitle,
      };
    case types.GET_AUDIO_DATA_ERR:
      return {
        ...state,
        isLoading: false,
      };

    case types.CLEAR_DATA_ERR:
      return {
        isLoading: false,
        viewerShipId: "",
        viewerId: "",
        audioUrl: "",
        assetId: "",
      };
    default:
      return intialData;
  }
};
