import React from "react";
import "./style/legalNotices.css";
export const Title = ({ children }) => {
  return (
    <h2 style={{ marginBottom: "10px", marginTop: "20px" }}>{children}</h2>
  );
};

export const SubTitle = ({ children }) => {
  return (
    <p
      style={{
        fontWeight: "500",
      }}
      className="legal-notices-subtitle"
    >
      {children}
    </p>
  );
};

export const Container = ({ children, ...rest }) => {
  return <div className="legal-notices-container">{children}</div>;
};
