import React, { useEffect, useState } from "react";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./AllSupport.module.css";
import { generateTokenHandler } from "../../utils/json-web";
export const AllSupportTickets = ({ getToast, getDeviceType }) => {
  const { t } = useTranslation();

  const authUser = localStorage.getItem("authUser");
  const navigate = useNavigate();
  const [supportTickets, setSupportTickets] = useState([]);
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }

  const getAllSupportTickets = async () => {
    try {
      let slug = await generateTokenHandler();
      let headers = {
        authorization: authUser,
        devicetype: getDeviceType(),
        slug: slug,
      };
      if (
        deviceDetailsFromApp &&
        (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
      ) {
        headers.appid =
          deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
      }
      const config = {
        method: "get",
        headers: headers,
        url: movieBaseUrl + "getSupportTickets",
      };
      axios(config)
        .then((res) => {
          if (res.data.status) {
            getToast(res.data.message, "top-right", "success");
            setSupportTickets(res.data.data);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.response_code == 401) {
            navigate("/signin");
            localStorage.clear();
            getToast(
              t("Your token is expired. Please login again."),
              "top-right",
              "error"
            );
          } else {
            getToast(err?.response?.data?.message, "top-right", "error");
          }
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getAllSupportTickets();
  }, []);

  function convertTimestamp(timestamp = "") {
    const dt = new Date(timestamp);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = dt.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  return (
    <div className={styles.order_history_container}>
      <div className={styles.header_container}></div>

      {/* history box */}
      <div className={styles.historys}>
        <div className={styles.history_header}>
          <div>{t("Ticket Id")}</div>
          <div>{t("Type")}</div>
          {/* <div>{t("Quantity")}</div> */}
          <div>{t("Description")}</div>
          <div>{t("Date & Time")}</div>
          <div>{t("Assigned")}</div>
        </div>
        {supportTickets?.map((el, i) => {
          return (
            <div key={i} className={styles.order_history_items}>
              <div>{el?.ticketNumber}</div>
              <div>{el?.relatedTo}</div>
              <div>{el?.description}</div>
              <div>{convertTimestamp(el?.createdAt)}</div>
              <div>{el?.status}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
