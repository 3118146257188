import React, { useRef } from "react";

function VideoDurationCalculator(props) {
  const videoRef = useRef(null);
  var filePath = props.filePath;
  var callbackFn = props.callbackFunction;

  const handleVideoLoadedMetadata = () => {
    const duration = videoRef.current.duration;
    var video = document.getElementById("vid1");
    callbackFn(duration);
    console.log(`Video duration: ${duration} seconds`);
  };

  return (
    <div>
      <video
        id="vid1"
        ref={videoRef}
        preload="metadata"
        controls="false"
        onLoadedMetadata={handleVideoLoadedMetadata}
        muted
        autoPlay="false"
        style={{ display: "none" }}
        src={filePath}
      />
    </div>
  );
}

export default VideoDurationCalculator;
