import React, { useEffect, useState } from "react";
import styles from "./HomeBanner.module.css";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../../assets/new-images/banners/Cash_out.jpg";
import img2 from "../../assets/new-images/banners/Devils_Deal.jpg";
import img3 from "../../assets/new-images/banners/Gangam_Zombie.jpg";
import img4 from "../../assets/new-images/banners/Ghost_Station_Cover_art.jpg";
import img5 from "../../assets/new-images/banners/love.jpg";
import img6 from "../../assets/new-images/banners/Naughty_Girl.jpg";
import img7 from "../../assets/new-images/banners/TARGET.jpg";
import { useTranslation } from "react-i18next";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  createJWT,
  generateToken,
  generateTokenHandler,
} from "../../utils/json-web";

export const HomeBanner = ({ getToast, getDeviceType }) => {
  const { t } = useTranslation();
  const [banners, setBanners] = useState([]);
  const authUser = localStorage.getItem("authUser");
  const navigate = useNavigate();

  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const handleTrailerAndVideoClick = (videoId, isSeries, seasonNumber) => {
    sessionStorage.setItem("banner", videoId);
    navigate(`/details/${videoId}`);
    // let headers = {
    //   authorization: authUser,
    //   devicetype: getDeviceType(),
    // };

    // console.log(headers.authorization);
    // console.log(headers.devicetype);

    // if (
    //   deviceDetailsFromApp &&
    //   (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    // ) {
    //   headers.appid =
    //     deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    // }
    // console.log(headers.appid);
    // console.log("headers", headers);

    // let data;
    // if (isSeries) {
    //   data = {
    //     videoId,
    //     trailer: false,
    //     seasonNumber,
    //     banner: true,
    //   };
    // } else {
    //   data = {
    //     videoId,
    //     trailer: false,
    //     banner: true,
    //   };
    // }

    // let config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: movieBaseUrl + `videoClickOrTrailerPlayed`,
    //   headers: headers,
    //   data,
    // };

    // axios
    //   .request(config)
    //   .then((res) => {
    //     console.log("res: ", res.data);
    //   })
    //   .catch((err) => {
    //     if (err?.response?.data?.response_code == 401) {
    //       navigate("/signin");
    //       localStorage.clear();
    //       getToast(
    //         t("Your token is expired. Please login again."),
    //         "top-right",
    //         "error"
    //       );
    //     }
    //   })
    //   .finally(() => {

    // });
  };

  // getting banners
  const getBannerList = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log("headers: ", headers);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "getBannerList",
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        const data = res?.data?.data;
        if (res.data.status) {
          console.log("data: ", data);
          setBanners(data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  useEffect(() => {
    getBannerList();
  }, []);
  return (
    <div className={styles.carousel_container_box}>
      <Carousel fade className={styles.carousel_container}>
        {banners?.map((el, i) => {
          return (
            <Carousel.Item key={i} className={styles.banner_box}>
              <img
                onClick={() =>
                  handleTrailerAndVideoClick(
                    el?.videoId,
                    el?.isSeries,
                    el?.seasonNumber
                  )
                }
                src={el?.backgroundImage?.file}
                alt={el?.title}
                className={styles.banner_images}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
