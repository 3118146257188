import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import styles from "./Layout.module.css";
import { TopNavbar } from "./TopNavbar/TopNavbar";

const Layout = ({ getDeviceType }) => {
  const [sidebarOpen, setSideBar] = useState(false);
  const handleClose = () => setSideBar(false);
  const handleShow = () => setSideBar(true);
  return (
    <>
      <div className={styles.layout_wrapper}>
        <TopNavbar
          setSideBar={setSideBar}
          sidebarOpen={handleShow}
          getDeviceType={getDeviceType}
        />
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
