import React from "react";
import styles from "./WatchItem.module.css";
import { useNavigate } from "react-router-dom";

export const WatchItem = ({ keyArt2, title, videoId, transactionIds = [] }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    console.log(e.target.tagName);
    if (e.target.tagName == "BUTTON" || e.target.tagName == "button") {
      //   handleDeleteFavourite(e, videoId);
      sessionStorage.setItem("videoRated", videoId);
      sessionStorage.setItem("transcRated", transactionIds[0]);
      navigate("/rating-and-reviews");
      console.log("btn clickes");
    } else {
      navigate(`/details/${videoId}`);
    }
  };
  return (
    <div className={styles.content_item} onClick={handleClick}>
      <div className={styles.img_container}>
        <img src={keyArt2?.file} alt="" />
      </div>
      <div className={styles.content_item_header}>
        <h5>{title}</h5>
      </div>
      <div className={styles.btn_container}>
        <button className={styles.rating_btn} onClick={handleClick}>
          {"Rate"}
        </button>
      </div>
    </div>
  );
};
