import React from 'react';
import styles from './SeriesHeader.module.css';

const SeriesHeader = ({ seasonsNumber, setSeasonsNumber, totalSeason}) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerSelectBox}>
        <select
             className={`${styles.option} ${totalSeason === 1 ? styles.singleSeason : ''}`}
             value={seasonsNumber}
             onChange={(e) => setSeasonsNumber(e.target.value)}
             disabled={totalSeason === 1} // Disable if only one option
        >
          {Array.from({ length: totalSeason }, (_, index) => (
            <option key={index} value={index + 1}>
              Season {index + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SeriesHeader;
