import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import { Navbar } from "../../components/navbar/Navbar";
import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";
import ticket from "../../assets/new-images/ticket.png";
import left_curtain from "../../assets/new-images/left_parda.png";
import right_curtain from "../../assets/new-images/right_parda.png";
import digital_cinema from "../../assets/new-images/digital_cinema.png";
import creator from "../../assets/new-images/creator1.png";
import creator1 from "../../assets/new-images/creator.png";
import { LandingFooterV2 } from "../../components/v2-footer/LandingFooterV2";
export default function Home() {
  window.trackUser(null);

  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");
  const [ErrStatus, setErrStatus] = useState(true);
  // const authTokenFromApp = JSON.stringify(window.authTokenFromApp) || "";
  const authUser = localStorage.getItem("authUser") || "";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (email.trim() == "") {
      setMessage("Email is Require");
      setErrStatus(false);
      setTimeout(() => {
        setMessage("");
        setErrStatus(true);
      }, 3000);
      return;
    }
    if (!regex.test(email.trim())) {
      setMessage("Please enter a valid email");
      setErrStatus(false);
      setTimeout(() => {
        setMessage("");
        setErrStatus(true);
      }, 3000);
      return;
    }

    navigate("/signup", { state: { email: email } });
  };
  useEffect(() => {
    // if (authUser == "" && authTokenFromApp != "") {
    //   localStorage.setItem("authUser", authTokenFromApp);
    // }
  }, []);
  return (
    // <div>
    //   <HeaderContainer {...{'signin':true,home:true}}>
    //     <Feature>
    //       <Feature.Title>Films, TV shows and more</Feature.Title>
    //       <Feature.SubTitle>Watch anywhere, anytime.</Feature.SubTitle>

    //       <OptForm>
    //         <OptForm.Text>
    //           Don't miss out on the opportunity to unlock thrilling discounts
    //           and awards. Sign up today to avail exciting offers!
    //         </OptForm.Text>
    //         <OptForm.Text>
    //           Ready to watch? Enter your email to create account.
    //         </OptForm.Text>
    //         <OptForm.Break />
    //         <OptForm.Input
    //           placeholder="Email address"
    //           value={email}
    //           status={ErrStatus}
    //           Errmessage={message}
    //           onChange={(e) => setEmail(e.target.value)}
    //         />
    //         <OptForm.Button
    //           onClick={handleClick}
    //           style={{
    //             backgroundImage:
    //               "linear-gradient(to right, #1c6cbe, #24a2a0, #1c6cbe)",
    //           }}
    //         >
    //           Register
    //         </OptForm.Button>
    //         <OptForm.Break />
    //       </OptForm>
    //     </Feature>
    //   </HeaderContainer>{" "}
    //   <JumbotronContainer />
    //   {/* <FaqsContainer /> */}
    //   {/* <FooterContainer /> */}
    //   <LandingFooter />
    // </div>
    <div>
      {/* navbar  */}
      <Navbar />
      {/* welcome section */}

      <div className={styles.welcome_container}>
        <div className={styles.welcome_box}>
          <div className={styles.welcome_text_box}>
            <div>
              <span>{t("Discover")}</span> {t("a world of")}
            </div>
            <div>
              <span>
                {t("captivating stories")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="7"
                  viewBox="0 0 477 7"
                  fill="none"
                >
                  <path
                    d="M474.357 1.21099C462.108 0.527919 449.807 0.160112 437.454 0.175125C303.547 0.366534 169.64 -0.0350508 35.7592 1.23726C23.1784 1.35736 11.2266 2.427 0 4.47246C0.68146 4.95286 1.38913 5.42576 2.08806 5.9024C5.26821 5.47079 8.50951 5.16304 11.8382 5.04294C30.4385 4.38239 49.0476 3.39156 67.7965 4.04085C73.9471 4.25103 80.124 4.43868 86.292 4.44619C120.828 4.48747 155.355 4.47997 189.865 5.37697C212.336 5.96245 234.85 6.015 257.365 5.9587C294.111 5.86863 330.849 6.05253 367.595 6.04127C393.237 6.03376 418.871 5.5421 444.513 5.8949C454.411 6.03001 463.865 4.8778 473.44 4.04836C476.332 3.7969 476.454 2.7873 476.926 1.95036C477.398 1.10215 475.51 1.27855 474.349 1.21474L474.357 1.21099Z"
                    fill="#00C09F"
                  />
                </svg>
              </span>{" "}
              {t("&")}
            </div>
            <div>{t("groundbreaking content")}</div>
            <div>{t("right at your fingertips")}</div>
          </div>
          <div></div>
        </div>
      </div>

      {/* signup section */}
      <div className={styles.signup_container}>
        <div className={styles.signup_text_box}>
          <div className={styles.signup_text_1}>
            {t("Become part of first")}
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <g clip-path="url(#clip0_83_11)">
                <path
                  d="M16.768 6.68257C16.1069 7.74375 15.4813 8.81751 14.7278 9.8421C13.5863 11.3887 12.3633 12.8929 10.7869 14.1926C10.2968 14.5979 9.74862 14.9584 9.2387 15.3463C8.81217 15.6695 8.44367 16.0241 8.16886 16.4493C7.74534 17.1034 8.16856 17.6134 8.90551 17.7291C9.78967 17.8695 10.6359 17.6701 11.4458 17.323C12.5098 16.8688 13.4528 16.2727 14.3229 15.5889C15.8181 14.4182 17.0742 13.0966 18.2432 11.7146C19.2417 10.5331 20.2006 9.33024 20.917 8.03615C21.656 6.69604 22.2299 5.31161 22.3011 3.85655C22.3216 3.45848 22.2625 3.08058 22.0155 2.7425C21.4648 1.98903 20.156 2.01144 19.3984 2.79102C18.841 3.36279 18.4343 4.00437 18.0324 4.64696C17.8206 4.98644 17.611 5.3255 17.4014 5.66456C17.1918 6.00362 16.9799 6.3431 16.7704 6.68216L16.768 6.68257Z"
                  fill="white"
                />
                <path
                  d="M12.7008 23.5133C13.3725 23.7152 14.2166 23.7791 15.0821 23.7515C16.83 23.697 18.5399 23.4074 20.187 22.8245C21.5053 22.3564 22.7162 21.7289 23.744 20.8792C24.0047 20.6643 24.2132 20.4111 24.3476 20.1295C24.7021 19.3832 24.0726 18.7069 23.1062 18.7814C22.4047 18.8345 21.7673 19.0836 21.1245 19.3165C19.5218 19.8952 17.9397 20.5216 16.266 20.9322C15.5396 21.1099 14.8147 21.2701 14.062 21.3039C13.6632 21.3211 13.3016 21.2686 12.9563 21.1351C12.7876 21.0705 12.6106 21.0131 12.4298 20.9735C11.5636 20.7879 11.1457 21.3153 11.1068 21.8215C11.0723 22.2602 11.2748 22.6292 11.6263 22.9274C11.8962 23.1565 12.2072 23.3496 12.7011 23.5152L12.7008 23.5133Z"
                  fill="white"
                />
                <path
                  d="M5.06792 13.1509C5.53242 12.657 5.88715 12.0039 6.1404 11.3159C6.65443 9.92898 6.87583 8.52855 6.72366 7.12738C6.60297 6.00435 6.25239 4.93831 5.58059 3.97632C5.40999 3.73106 5.17697 3.52191 4.88487 3.3673C4.11078 2.9554 3.09659 3.33008 2.86599 4.1035C2.69727 4.66756 2.78455 5.21371 2.84814 5.76035C3.00613 7.12807 3.23123 8.48552 3.1678 9.87822C3.13941 10.4816 3.08929 11.0831 2.88184 11.6828C2.7706 12.0002 2.58782 12.2772 2.3141 12.5251C2.18151 12.6463 2.05256 12.7745 1.94668 12.9119C1.43659 13.5608 1.92964 13.9837 2.52048 14.1069C3.03195 14.2122 3.53819 14.1183 4.01144 13.8952C4.37525 13.7244 4.70766 13.5116 5.06825 13.1527L5.06792 13.1509Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_83_11">
                  <rect
                    width="22.0195"
                    height="22.3936"
                    fill="white"
                    transform="matrix(-0.983908 0.178674 0.178674 0.983908 21.665 0)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className={styles.signup_text_2}>{t("1000")}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <g clip-path="url(#clip0_83_15)">
                <path
                  d="M4.32306 10.3564C4.99406 10.0657 5.66725 9.79759 6.3304 9.4477C7.33196 8.91702 8.32106 8.33313 9.2448 7.5162C9.53262 7.26233 9.8036 6.96905 10.0845 6.70152C10.3188 6.47762 10.5626 6.29176 10.8328 6.16932C11.2485 5.98052 11.4725 6.28621 11.4382 6.74086C11.3978 7.28649 11.1758 7.77514 10.8774 8.22708C10.4865 8.821 10.0336 9.32797 9.54218 9.78222C8.70016 10.5633 7.80698 11.1855 6.89219 11.7492C6.11025 12.2307 5.32182 12.6862 4.51549 12.9867C3.68087 13.2961 2.84383 13.5019 2.03481 13.3984C1.81334 13.3706 1.61353 13.2971 1.46057 13.1147C1.11971 12.7078 1.30597 11.9233 1.83503 11.5463C2.2233 11.2687 2.62992 11.0887 3.03643 10.9118C3.25114 10.8186 3.46531 10.7267 3.67948 10.6348C3.89365 10.5429 4.10835 10.4497 4.32252 10.3578L4.32306 10.3564Z"
                  fill="white"
                />
                <path
                  d="M14.1122 9.60403C14.1339 10.0281 14.0568 10.542 13.9266 11.0595C13.6644 12.1047 13.278 13.1035 12.7388 14.035C12.3064 14.7805 11.8006 15.4453 11.1972 15.9777C11.0444 16.1128 10.8776 16.2127 10.705 16.2651C10.2477 16.4031 9.95974 15.9567 10.1291 15.3833C10.2515 14.9669 10.4731 14.6088 10.6865 14.2458C11.2175 13.3406 11.772 12.4525 12.2201 11.4877C12.4142 11.0688 12.5986 10.6492 12.7172 10.2002C12.7797 9.96211 12.7989 9.7395 12.7714 9.51847C12.7583 9.41055 12.7503 9.29842 12.7526 9.18574C12.7657 8.64637 13.1111 8.44814 13.3944 8.47567C13.6401 8.49905 13.8159 8.65794 13.933 8.8992C14.0231 9.08451 14.0878 9.29087 14.1132 9.60442L14.1122 9.60403Z"
                  fill="white"
                />
                <path
                  d="M9.43231 3.97334C9.09915 4.2029 8.69313 4.35046 8.28141 4.43351C7.45098 4.60304 6.65201 4.59529 5.90227 4.3629C5.30118 4.17741 4.76197 3.85946 4.32262 3.35886C4.21052 3.23165 4.12656 3.07053 4.08041 2.87939C3.95694 2.37264 4.29762 1.80065 4.75327 1.7398C5.08565 1.6951 5.37416 1.8025 5.6661 1.8957C6.39669 2.12822 7.11271 2.40005 7.88645 2.50198C8.22177 2.54559 8.559 2.57596 8.9161 2.51156C9.10529 2.47661 9.28182 2.39459 9.45438 2.25497C9.53863 2.18746 9.62621 2.12284 9.71575 2.073C10.1401 1.83162 10.307 2.17055 10.2962 2.53812C10.2861 2.85617 10.1672 3.15105 9.98173 3.4131C9.8395 3.61462 9.6784 3.79305 9.43328 3.97373L9.43231 3.97334Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_83_15">
                  <rect
                    width="13.5972"
                    height="12.4642"
                    fill="white"
                    transform="translate(0 12.6281) rotate(-68.2381)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className={styles.signup_text_3}>
            <div></div>
            {t("users to")}{" "}
            <span>
              {t("unlock thrilling")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="7"
                viewBox="0 0 218 7"
                fill="none"
              >
                <path
                  d="M216.792 1.63876C211.194 1.00443 205.572 0.662861 199.926 0.676802C138.728 0.854556 77.5293 0.481621 16.3428 1.66316C10.5931 1.77469 5.13082 2.76802 0 4.66755C0.311443 5.11368 0.634864 5.55283 0.954293 5.99547C2.40769 5.59466 3.88904 5.30886 5.41032 5.19732C13.9111 4.5839 22.4159 3.66376 30.9846 4.26673C33.7955 4.46191 36.6185 4.63618 39.4375 4.64315C55.2212 4.68149 71.001 4.67452 86.7728 5.50752C97.0424 6.05124 107.332 6.10003 117.622 6.04775C134.416 5.9641 151.206 6.13489 167.999 6.12443C179.719 6.11746 191.434 5.66088 203.153 5.9885C207.676 6.11398 211.997 5.04397 216.373 4.2737C217.695 4.04018 217.75 3.10262 217.966 2.32538C218.182 1.53769 217.319 1.7015 216.788 1.64225L216.792 1.63876Z"
                  fill="#0D1626"
                />
              </svg>
            </span>
          </div>

          <div className={styles.signup_text_4}>
            {t("discounts and awards like nobody else!")}
          </div>
        </div>

        <div className={styles.signup_btn_box}>
          <NavLink to="/signup" className={styles.signup_btn_box}>
            <button>{t("Sign up Now")}</button>
          </NavLink>
        </div>
      </div>

      {/* ticket banner  */}

      <div className={styles.ticket_container}>
        <div>
          <img src={ticket} alt="ticket" />
        </div>

        <div className={styles.ticket_details_box}>
          <div>{t("Pay for what you like!")}</div>
          <div>{t("Pay for what you like! desc")}</div>
          <div className={styles.ticket_box}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
              >
                <g clip-path="url(#clip0_79_159)">
                  <path
                    d="M7.13428 20.7682H52.9622V49.6139C52.9622 50.6342 52.1304 51.4623 51.1054 51.4623H8.99116C7.96616 51.4623 7.13428 50.6342 7.13428 49.6139V20.7682Z"
                    fill="url(#paint0_linear_79_159)"
                  />
                  <path
                    d="M53.6157 21.5076H6.48043C5.16576 21.5076 4.09619 20.443 4.09619 19.1343V8.56166C4.09619 7.25301 5.16576 6.18835 6.48043 6.18835H53.6157C54.9303 6.18835 55.9999 7.25301 55.9999 8.56166V19.1343C55.9999 20.443 54.9303 21.5076 53.6157 21.5076ZM6.48043 7.66705C5.98279 7.66705 5.5817 8.06999 5.5817 8.56166V19.1343C5.5817 19.6297 5.9865 20.0289 6.48043 20.0289H53.6157C54.1133 20.0289 54.5144 19.626 54.5144 19.1343V8.56166C54.5144 8.0663 54.1096 7.66705 53.6157 7.66705H6.48043Z"
                    fill="url(#paint1_linear_79_159)"
                  />
                  <path
                    d="M20.8825 25.4631H14.1828C13.4957 25.4631 12.9387 26.0176 12.9387 26.7015V30.0027C12.9387 30.6867 13.4957 31.2411 14.1828 31.2411H20.8825C21.5696 31.2411 22.1266 30.6867 22.1266 30.0027V26.7015C22.1266 26.0176 21.5696 25.4631 20.8825 25.4631Z"
                    fill="white"
                  />
                  <path
                    d="M33.3979 25.4631H26.6982C26.0111 25.4631 25.4541 26.0176 25.4541 26.7015V30.0027C25.4541 30.6867 26.0111 31.2411 26.6982 31.2411H33.3979C34.085 31.2411 34.642 30.6867 34.642 30.0027V26.7015C34.642 26.0176 34.085 25.4631 33.3979 25.4631Z"
                    fill="white"
                  />
                  <path
                    d="M45.8205 25.4631H39.1208C38.4337 25.4631 37.8767 26.0176 37.8767 26.7015V30.0027C37.8767 30.6867 38.4337 31.2411 39.1208 31.2411H45.8205C46.5076 31.2411 47.0646 30.6867 47.0646 30.0027V26.7015C47.0646 26.0176 46.5076 25.4631 45.8205 25.4631Z"
                    fill="white"
                  />
                  <path
                    d="M20.9272 34.01H14.2275C13.5404 34.01 12.9834 34.5645 12.9834 35.2484V38.5496C12.9834 39.2336 13.5404 39.788 14.2275 39.788H20.9272C21.6143 39.788 22.1713 39.2336 22.1713 38.5496V35.2484C22.1713 34.5645 21.6143 34.01 20.9272 34.01Z"
                    fill="white"
                  />
                  <path
                    d="M33.4425 34.01H26.7429C26.0558 34.01 25.4988 34.5645 25.4988 35.2484V38.5496C25.4988 39.2336 26.0558 39.788 26.7429 39.788H33.4425C34.1296 39.788 34.6867 39.2336 34.6867 38.5496V35.2484C34.6867 34.5645 34.1296 34.01 33.4425 34.01Z"
                    fill="white"
                  />
                  <path
                    d="M45.8651 34.01H39.1655C38.4784 34.01 37.9214 34.5645 37.9214 35.2484V38.5496C37.9214 39.2336 38.4784 39.788 39.1655 39.788H45.8651C46.5523 39.788 47.1093 39.2336 47.1093 38.5496V35.2484C47.1093 34.5645 46.5523 34.01 45.8651 34.01Z"
                    fill="white"
                  />
                  <path
                    d="M33.4425 42.5531H26.7429C26.0558 42.5531 25.4988 43.1076 25.4988 43.7915V47.0927C25.4988 47.7766 26.0558 48.3311 26.7429 48.3311H33.4425C34.1296 48.3311 34.6867 47.7766 34.6867 47.0927V43.7915C34.6867 43.1076 34.1296 42.5531 33.4425 42.5531Z"
                    fill="white"
                  />
                  <path
                    d="M28.6852 36.8971H2.26912C1.01592 36.8971 0 37.9084 0 39.1558V54.7376C0 55.985 1.01592 56.9963 2.26912 56.9963H28.6852C29.9384 56.9963 30.9543 55.985 30.9543 54.7376V39.1558C30.9543 37.9084 29.9384 36.8971 28.6852 36.8971Z"
                    fill="#00C09F"
                  />
                  <path
                    d="M0 41.3333H30.9543C30.9543 41.3333 30.9543 41.666 30.9543 42.0726C30.9543 42.4792 30.9543 42.8119 30.9543 42.8119H0"
                    fill="white"
                  />
                  <path
                    d="M14.8663 13.5744C11.821 13.5744 9.34766 10.5283 9.34766 6.78721C9.34766 3.04611 11.8247 0 14.8663 0C16.9275 0 18.7992 1.38997 19.7537 3.6265C19.9133 4.00357 19.7388 4.43609 19.36 4.59505C18.9812 4.754 18.5467 4.58026 18.387 4.20319C17.6702 2.52117 16.3184 1.4787 14.8663 1.4787C12.6418 1.4787 10.8332 3.85939 10.8332 6.78721C10.8332 9.71503 12.6418 12.0957 14.8663 12.0957C15.2748 12.0957 15.6091 12.4284 15.6091 12.8351C15.6091 13.2417 15.2748 13.5744 14.8663 13.5744Z"
                    fill="url(#paint2_linear_79_159)"
                  />
                  <path
                    d="M30.0072 13.5744C26.9619 13.5744 24.4885 10.5283 24.4885 6.78721C24.4885 3.04611 26.9656 0 30.0072 0C32.0683 0 33.9401 1.38997 34.8945 3.6265C35.0542 4.00357 34.8797 4.43609 34.5009 4.59505C34.1221 4.754 33.6875 4.58026 33.5279 4.20319C32.8111 2.52117 31.4593 1.4787 30.0072 1.4787C27.7826 1.4787 25.974 3.85939 25.974 6.78721C25.974 9.71503 27.7826 12.0957 30.0072 12.0957C30.4157 12.0957 30.7499 12.4284 30.7499 12.8351C30.7499 13.2417 30.4157 13.5744 30.0072 13.5744Z"
                    fill="url(#paint3_linear_79_159)"
                  />
                  <path
                    d="M45.141 13.5744C42.0957 13.5744 39.6223 10.5283 39.6223 6.78721C39.6223 3.04611 42.0957 0 45.141 0C47.2021 0 49.0739 1.38997 50.0283 3.6265C50.188 4.00357 50.0135 4.43609 49.6346 4.59505C49.2558 4.754 48.8213 4.58026 48.6616 4.20319C47.9449 2.52117 46.5931 1.4787 45.141 1.4787C42.9164 1.4787 41.1078 3.85939 41.1078 6.78721C41.1078 9.71503 42.9164 12.0957 45.141 12.0957C45.5495 12.0957 45.8837 12.4284 45.8837 12.8351C45.8837 13.2417 45.5495 13.5744 45.141 13.5744Z"
                    fill="url(#paint4_linear_79_159)"
                  />
                  <path
                    d="M25.5212 48.1315H18.8215C18.1344 48.1315 17.5774 48.6859 17.5774 49.3699V52.6711C17.5774 53.355 18.1344 53.9095 18.8215 53.9095H25.5212C26.2083 53.9095 26.7653 53.355 26.7653 52.6711V49.3699C26.7653 48.6859 26.2083 48.1315 25.5212 48.1315Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_79_159"
                    x1="7.13428"
                    y1="36.1171"
                    x2="52.9622"
                    y2="36.1171"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_79_159"
                    x1="4.09248"
                    y1="13.848"
                    x2="55.9999"
                    y2="13.848"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_79_159"
                    x1="9.34766"
                    y1="6.78721"
                    x2="19.8168"
                    y2="6.78721"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_79_159"
                    x1="24.4885"
                    y1="6.78721"
                    x2="34.9577"
                    y2="6.78721"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_79_159"
                    x1="39.6186"
                    y1="6.78721"
                    x2="50.0877"
                    y2="6.78721"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <clipPath id="clip0_79_159">
                    <rect width="56" height="57" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div>{t("No subscription cost")}</div>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="58"
                height="40"
                viewBox="0 0 58 40"
                fill="none"
              >
                <g clip-path="url(#clip0_79_177)">
                  <path
                    d="M50.2705 31.4251C50.2705 29.8312 51.5371 28.5398 53.1003 28.5398V25.7873C51.5371 25.7873 50.2705 24.4959 50.2705 22.9019C50.2705 21.308 51.5371 20.0166 53.1003 20.0166V17.2641C51.5371 17.2641 50.2705 15.9727 50.2705 14.3787C50.2705 12.7848 51.5371 11.4934 53.1003 11.4934V8.28703C53.1003 6.91815 52.0111 5.80756 50.6686 5.80756H2.43174C1.08922 5.80756 0 6.91815 0 8.28703V11.4934C1.56326 11.4934 2.8298 12.7848 2.8298 14.3787C2.8298 15.9727 1.56326 17.2641 0 17.2641V20.0166C1.56326 20.0166 2.8298 21.308 2.8298 22.9019C2.8298 24.4959 1.56326 25.7873 0 25.7873V28.5398C1.56326 28.5398 2.8298 29.8312 2.8298 31.4251C2.8298 33.0191 1.56326 34.3105 0 34.3105V37.5168C0 38.8857 1.08922 39.9963 2.43174 39.9963H50.6722C52.0147 39.9963 53.1039 38.8857 53.1039 37.5168V34.3105C51.5407 34.3105 50.2741 33.0191 50.2741 31.4251H50.2705Z"
                    fill="#00C09F"
                  />
                  <path
                    d="M55.1702 25.6176C55.1702 24.0236 56.4367 22.7322 58 22.7322V19.9797C56.4367 19.9797 55.1702 18.6883 55.1702 17.0944C55.1702 15.5004 56.4367 14.209 58 14.209V11.4565C56.4367 11.4565 55.1702 10.1651 55.1702 8.57117C55.1702 6.97722 56.4367 5.68582 58 5.68582V2.47948C58 1.1106 56.9108 0 55.5682 0H7.3314C5.98888 0 4.89966 1.1106 4.89966 2.47948V5.68582C6.46292 5.68582 7.72946 6.97722 7.72946 8.57117C7.72946 10.1651 6.46292 11.4565 4.89966 11.4565V14.209C6.46292 14.209 7.72946 15.5004 7.72946 17.0944C7.72946 18.6883 6.46292 19.9797 4.89966 19.9797V22.7322C6.46292 22.7322 7.72946 24.0236 7.72946 25.6176C7.72946 27.2115 6.46292 28.5029 4.89966 28.5029V31.7093C4.89966 33.0781 5.98888 34.1887 7.3314 34.1887H55.5719C56.9144 34.1887 58.0036 33.0781 58.0036 31.7093V28.5029C56.4403 28.5029 55.1738 27.2115 55.1738 25.6176H55.1702Z"
                    fill="url(#paint0_linear_79_177)"
                  />
                  <path
                    d="M37.6594 8.91062C39.6497 8.88848 41.2564 10.5267 41.2745 12.556C41.2745 14.5854 39.6678 16.2236 37.6775 16.2236C35.709 16.2457 34.0806 14.6038 34.0806 12.5782C34.0806 10.5525 35.6873 8.91062 37.6558 8.91062H37.6594ZM37.6196 13.9286H37.7209C38.6654 13.9286 39.1069 13.4785 39.1069 12.5782C39.1069 11.6779 38.6654 11.2056 37.7209 11.2056H37.6413C36.6788 11.2056 36.2337 11.6558 36.2337 12.556C36.2337 13.4563 36.6969 13.9286 37.6196 13.9286ZM44.5494 9.17628H47.0607L39.3674 23.4111H36.8561L44.5494 9.17628ZM46.2755 16.3454C48.2657 16.3454 49.8724 17.9651 49.8724 19.9908C49.8941 22.0201 48.2838 23.6583 46.2972 23.6583C44.3069 23.6805 42.7002 22.0386 42.7002 20.0129C42.7002 17.9873 44.2888 16.3454 46.2755 16.3454ZM46.2357 21.3855H46.3153C47.2778 21.3855 47.7229 20.9132 47.7229 20.0129C47.7229 19.1126 47.2815 18.6404 46.337 18.6404H46.2574C45.2948 18.6404 44.8497 19.0905 44.8497 19.9908C44.8497 20.8911 45.3129 21.3818 46.2357 21.3818V21.3855Z"
                    fill="white"
                  />
                  <path
                    d="M27.8203 9.2796H12.2456V11.7812H27.8203V9.2796Z"
                    fill="#0D1626"
                  />
                  <path
                    d="M24.5744 15.1831H12.2456V17.6847H24.5744V15.1831Z"
                    fill="#0D1626"
                  />
                  <path
                    d="M24.5744 21.0867H12.2456V23.5883H24.5744V21.0867Z"
                    fill="#0D1626"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_79_177"
                    x1="4.89966"
                    y1="17.0944"
                    x2="58"
                    y2="17.0944"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0048C4" />
                    <stop offset="0.36" stop-color="#0279C2" />
                    <stop offset="0.51" stop-color="#0284BB" />
                    <stop offset="0.79" stop-color="#02A2AA" />
                    <stop offset="1" stop-color="#03BE9C" />
                  </linearGradient>
                  <clipPath id="clip0_79_177">
                    <rect width="58" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div>{t("Affordable Tickets")}</div>
            </div>
          </div>
        </div>
      </div>

      {/* digital cinema section */}

      <div className={styles.digital_container}>
        <img src={left_curtain} />
        <div className={styles.digital_container_details}>
          <div>{t("Experience the future of")}</div>
          <div>{t("digital cinema")}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="5"
            viewBox="0 0 53 5"
            fill="none"
          >
            <path
              d="M2 2.5H50.5"
              stroke="#B3FFB5"
              stroke-width="4"
              stroke-linecap="round"
            />
          </svg>
          <div>{t("Transform Any Screen into a Movie Theatre")}</div>
          <img src={digital_cinema} />
        </div>
        <img src={right_curtain} />
      </div>

      {/* explore section */}

      <div className={styles.explore_section}>
        <div>
          <div className={styles.explore_section_details}>
            <div>{t("explore text")}</div>
            <div className={styles.explore_details}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="29"
                  viewBox="0 0 20 29"
                  fill="none"
                >
                  <path
                    d="M2.13887 27.7742L19.5381 15.8474C20.1332 15.4394 20.1565 14.6989 19.5892 14.2682L2.19001 1.06827C1.35771 0.437361 0 0.91715 0 1.84274V26.9657C0 27.8649 1.29262 28.3522 2.13887 27.7742Z"
                    fill="white"
                  />
                </svg>
                <div>{t("resume")}</div>
              </div>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="29"
                  viewBox="0 0 23 29"
                  fill="none"
                >
                  <path
                    d="M7.24916 28.1299H1.54061C0.689754 28.1299 0 27.5825 0 26.9072V2.00246C0 1.32717 0.689754 0.779741 1.54061 0.779741H7.24916C8.10001 0.779741 8.78977 1.32717 8.78977 2.00246V26.9072C8.78977 27.5825 8.10001 28.1299 7.24916 28.1299Z"
                    fill="white"
                  />
                  <path
                    d="M21.1661 28.1299H15.4576C14.6067 28.1299 13.917 27.5825 13.917 26.9072V2.00246C13.917 1.32717 14.6067 0.779741 15.4576 0.779741H21.1661C22.017 0.779741 22.7068 1.32717 22.7068 2.00246V26.9072C22.7068 27.5825 22.017 28.1299 21.1661 28.1299Z"
                    fill="white"
                  />
                </svg>
                <div>{t("pause")}</div>
              </div>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="27"
                  viewBox="0 0 23 27"
                  fill="none"
                >
                  <g clip-path="url(#clip0_62_52)">
                    <path
                      d="M1.2573 0L5.91643 0C6.61087 0 7.17383 0.540422 7.17383 1.20707L7.17383 25.7929C7.17383 26.4596 6.61087 27 5.91643 27H1.2573C0.562863 27 -9.15527e-05 26.4596 -9.15527e-05 25.7929L-9.15527e-05 1.20707C-9.15527e-05 0.540422 0.562863 0 1.2573 0Z"
                      fill="white"
                    />
                    <path
                      d="M21.3614 25.6732L8.03234 14.878C7.5764 14.5087 7.55859 13.8385 7.99316 13.4486L21.3223 1.50449C21.9599 0.933437 23 1.36771 23 2.20547V24.9414C23 25.7552 22.0097 26.1964 21.3614 25.6732Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_62_52">
                      <rect
                        width="23"
                        height="27"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 27)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div>{t("rewind")}</div>
              </div>
            </div>

            <div>{t("whenever you want!")}</div>
            <div className={styles.explore_btn_box}>
              <NavLink to="/browse" className={styles.explore_btn_box}>
                <button>{t("explore")}</button>
              </NavLink>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {/* support creator section */}

      <div className={styles.support_section}>
        <div className={styles.support_section_img}>
          <img src={creator} alt="creator-img" />
          <img src={creator1} alt="creator-img" />
        </div>
        <div className={styles.support_section_details}>
          <div>{t("Support")}</div>
          <div>{t("Independent Creators")}</div>
          <div>
            {t(
              "By joining Hall IV, you actively contribute to the growth and success of talented artists and content creators from around the world."
            )}
          </div>
          <div>
            <NavLink to="/signup">
              <button>{t("Sign up Now")}</button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className={styles.footer_line}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="2"
          viewBox="0 0 1436 2"
          fill="none"
        >
          <path d="M0.5 1H1438" stroke="#D9D9D9" stroke-linecap="round" />
        </svg>
      </div>

      <LandingFooterV2 />
    </div>
  );
}
