import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducer as dataReducer } from "./dataReducer/reducer";
import { reducer as sinkReducer } from "./sinkReducer/reducer";

const combineReducer = combineReducers({
  dataReducer,
  sinkReducer,
});

export const store = legacy_createStore(combineReducer, applyMiddleware(thunk));
