import React from "react";
import styles from "./AddBuddy.module.css";
import { useTranslation } from "react-i18next";
import qr from "../../assets/new-images/BuyScreen/qr.png";
export const AddBuddy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.add_buddy_container}>
      <div className={styles.scaner_container}>
        <h4>{t("Scan code to join")}</h4>
        <img src={qr} alt="" />

        <div className={styles.social_container}>
          <div>{t("Share the link with your buddy")}</div>
          <input type="text" placeholder="www.halliv.com/watch/freedom" />
          <div></div>
        </div>
      </div>

      <div>
        <div className={styles.viewer_header}>
          <h4>{t("Viewers List")}</h4>
          <button>+{t("Add viewer")}</button>
        </div>
        <div className={styles.viewer_list}>
          <div>
            <div>{t("Name")}</div>
            <div>{t("Amount")}</div>
            <div>{t("Payment Status")}</div>
          </div>
          <div>
            <div>Piryanshu</div>
            <div>23</div>
            <div>Pending</div>
          </div>
          <div>
            <div>Mimi keene</div>
            <div>203</div>
            <div>Pending</div>
          </div>
        </div>

        <div className={styles.price_seen_container}>
          <div>
            <div>{t("Total Ticket price")}</div>
            <p>{t("Inclusive of GST")}</p>
          </div>
          <div>
            <h2>₹ 30</h2>
          </div>
        </div>

        <div className={styles.payment_btn}>
          <button>{t("Pay for one")}</button>
          <button>{t("Pay for all")}</button>
        </div>
        <div className={styles.helper_text}>
          {t("Payment to be done via Hall IV wallet")}
        </div>
      </div>
    </div>
  );
};
