import React from "react";
import styles from "./ContentList.module.css";
import ContentItem from "./ContentItem";
import { NavLink } from "react-router-dom";
export const ContentList = ({ data }) => {
  return (
    <div className={styles.content_list_container}>
      {data?.map((el, i) => {
        return (
          <NavLink to={`/details/${el?.videoId}`}>
            <ContentItem {...el} key={i} />
          </NavLink>
        );
      })}
    </div>
  );
};
