import React, { useState } from "react";
import styles from "./Support.module.css";
import { TicketCategories } from "./TicketCategories";
import { useTranslation } from "react-i18next";
import { AllSupportTickets } from "./AllSupportTickets";
import axios from "axios";
import { movieBaseUrl } from "../../Api-config/movie";
import { useNavigate } from "react-router-dom";
import { generateTokenHandler } from "../../utils/json-web";

export const Support = ({ getToast, getDeviceType }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const { t } = useTranslation();
  const [tabSelected, setTabSelcted] = useState("submit");
  const [formData, setFormData] = useState({
    subject: "",
    description: "",
  });
  const authUser = localStorage.getItem("authUser");
  const navigate = useNavigate();
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  const supportTickets = [
    "Account & Authentication",
    "Billing & Payments",
    "Content Access",
    "Technical Support",
    "General Inquiries & Feedback",
    "Report Abuse & Issues",
  ];

  const handleSelectCategory = (el) => {
    setSelectedCategory(el);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let slug = await generateTokenHandler();

    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }

    const data = { ...formData, relatedTo: selectedCategory };
    if (
      [
        "Account & Authentication",
        "Billing & Payments",
        "Report Abuse & Issues",
      ].includes(selectedCategory)
    ) {
      data.severity = "high";
    } else if (
      ["Content Access", "Technical Support"].includes(selectedCategory)
    ) {
      data.severity = "medium";
    } else {
      data.severity = "low";
    }
    console.log("data: ", data);
    const config = {
      method: "post",
      headers: headers,
      url: movieBaseUrl + "createSupportTickets",
      data: data,
    };
    axios(config)
      .then((res) => {
        if (res.data.status) {
          getToast(res.data.message, "top-right", "success");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  const handleTabChange = () => {
    setSelectedCategory("");
    if (tabSelected == "submit") {
      setTabSelcted("get");
    } else {
      setTabSelcted("submit");
    }
  };
  return (
    <div className={styles.support_container}>
      <h1>{t("Support")}</h1>

      <button onClick={handleTabChange} className={styles.support_btn}>
        {tabSelected == "submit" ? "View requests" : "Submit a Request"}
      </button>

      {tabSelected == "submit" ? (
        <>
          <div>
            <h4>What are you looking for ?</h4>
            <div className={styles.tickets_container}>
              {supportTickets?.map((el, i) => {
                return (
                  <TicketCategories
                    el={el}
                    key={i}
                    selectedCategory={selectedCategory}
                    handleSelectCategory={handleSelectCategory}
                  />
                );
              })}
            </div>
          </div>

          {selectedCategory != "" ? (
            <div className={styles.support_form}>
              <form onSubmit={handleSubmit}>
                <h4>{t("Submit a Request")}</h4>
                <div className={styles.form_items}>
                  <h6>{t("Subject")}</h6>
                  <input
                    type="text"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, subject: e.target.value })
                    }
                    value={formData?.subject}
                  />
                </div>

                <div className={styles.form_items}>
                  <h6>{t("Description")}</h6>
                  <textarea
                    type="text"
                    rows={4}
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    value={formData?.description}
                  />
                </div>

                {/* <div className={styles.form_items}>
            <h6>{t("Subject")}</h6>
            <input type="text" />
          </div> */}
                <div className={styles.support_btn_container}>
                  <button type="submit">{t("Submit")}</button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <AllSupportTickets getToast={getToast} getDeviceType={getDeviceType} />
      )}
    </div>
  );
};
