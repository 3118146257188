import React, { useEffect, useState } from "react";
import axios from "axios";
import { movieBaseUrl } from "../Api-config/movie";

export default function Payment({ getDeviceType }) {
  const [amount, setAmount] = useState(100);
  const [walletBalance, setWalletBalance] = useState(0);

  const token = localStorage.getItem("authUser");
  console.log(">>>>>the value of the token is : ", token);
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }
    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0].trim();
      var value = parts[1].trim().replace(/"/g, ""); // Remove double quotes around values

      result[key] = value;
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  const handlePayClick = async () => {
    try {
      console.log("The payment button is clicked......");

      const config = {
        method: "post",
        url: movieBaseUrl + "pay_page",
        headers: {
          Authorization: token,
          devicetype: getDeviceType(),
        },
        data: {
          // the amount must be send in paise only
          amount: amount,
        },
      };

      const result = await axios(config);
      console.log(">>>>>> the result of the handle pay click is : ", result);

      if (result.data.status == false) {
        console.log("the error in pay page api is : ", result.status.message);
      }

      if (
        result.data.data.success == true &&
        result.data.data.code == "PAYMENT_INITIATED"
      ) {
        sessionStorage.setItem(
          "merchantTransactionId",
          result.data.data.data.merchantTransactionId
        );
        window.location.href =
          result.data.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.log("the result of the axios is : ", result.data.data.message);
      }
    } catch (error) {
      console.log(" the error in the handle pay click is : ", error);
    }
  };

  const handleVideoPayClick = async () => {
    try {
      console.log("The video payment button is clicked......");

      const config = {
        method: "post",
        url: movieBaseUrl + "pay_page",
        headers: {
          Authorization: token,
          devicetype: getDeviceType(),
        },
        data: {
          // the amount must be send in rupees
          viewerShipId: "65a0de40bb1150dc63276932",
          viewerIds: ["6548b6fa237b8b4cebaaa179", "658bfb6c95a9334b302cbd7a"],
          amount: amount,
          videoId: "64d496ce77916415720b9762",
          videoDetails: {
            isSeries: false,
            numberOfViewers: 3,
            deviceType: "mobile",
            priceType: "PPEperSeason",
            category: "documentary",
            episode: null,
            seasonNumber: null,
          },
        },
      };

      const result = await axios(config);
      console.log(
        ">>>>>> the result of the handle video pay click is : ",
        result
      );

      if (result.data.status == false) {
        console.log(
          "the error in video pay page api is : ",
          result.status.message
        );
      }

      if (
        result.data.data.success == true &&
        result.data.data.code == "PAYMENT_INITIATED"
      ) {
        sessionStorage.setItem(
          "merchantTransactionId",
          result.data.data.data.merchantTransactionId
        );
        window.location.href =
          result.data.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.log("the result of the axios is : ", result.data.data.message);
      }
    } catch (error) {
      console.log(" the error in the handle video pay click is : ", error);
    }
  };

  const handlePayForVideoFromWalletClick = async () => {
    try {
      const config = {
        method: "post",
        url: movieBaseUrl + "pay/wallet",
        headers: {
          Authorization: token,
          devicetype: "laptop",
        },
        data: {
          // the amount must be send in rupees
          viewerShipId: "65a0de40bb1150dc63277453",
          viewerIds: ["655f22697cbd9a0ade289bb0", "65609728dbbae539d180d96a"],
          amount: 50,
          videoId: "64d496ce77916415720b9762",
          videoDetails: {
            isSeries: false,
            numberOfViewers: 2,
            deviceType: "mobile",
            priceType: "PPEperSeason",
            category: "movie",
            episode: null,
            seasonNumber: null,
          },
        },
      };

      const result = await axios(config);
      console.log(
        ">>>>>> the result of the handle video pay from wallet click is : ",
        result
      );

      if (result.data.status == false) {
        console.log(
          "the error in pay for video from wallet api is : ",
          result.status.message
        );
      }
      getWalletDetails();
    } catch (error) {
      console.log(" the error in the handle video pay click is : ", error);
    }
  };

  const getWalletDetails = () => {
    try {
      const config = {
        method: "get",
        url: movieBaseUrl + "walletDetails",
        headers: {
          Authorization: token,
          devicetype: getDeviceType(),
        },
      };
      axios(config)
        .then((response) => {
          console.log(
            "the response of the get wallet details api is : ",
            response
          );
          setWalletBalance(response.data.data.accountBalance);
        })
        .catch((error) => {
          console.log("the error in the get wallet details api is : ", error);
        });
    } catch (error) {
      console.log("the error in the get wallet details api is : ", error);
    }
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  return (
    <div style={{ display: "flex", gap: "50px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          width: "200px",
          padding: "20px",
          borderRadius: "7px",
        }}
      >
        <span>Wallet Balance</span>
        <span
          style={{
            border: "1px solid white",
            width: "70px",
            textAlign: "center",
            borderRadius: "5px",
            color: "green",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {walletBalance} ₹
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          width: "200px",
          padding: "20px",
          borderRadius: "7px",
        }}
      >
        <span>Recharge Wallet</span>
        <span
          style={{
            border: "1px solid white",
            width: "70px",
            textAlign: "center",
            borderRadius: "5px",
            color: "green",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {amount} ₹
        </span>
        <button
          style={{
            backgroundColor: "skyblue",
            borderRadius: "10px",
            padding: "2px 15px",
          }}
          onClick={() => handlePayClick()}
        >
          Pay
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          width: "200px",
          padding: "20px",
          borderRadius: "7px",
        }}
      >
        <span>You have to pay</span>
        <span
          style={{
            border: "1px solid white",
            width: "70px",
            textAlign: "center",
            borderRadius: "5px",
            color: "green",
          }}
        >
          50 ₹
        </span>
        <p>for this movie</p>
        <button
          style={{
            backgroundColor: "skyblue",
            borderRadius: "10px",
            padding: "2px 15px",
          }}
          onClick={() => handlePayForVideoFromWalletClick()}
        >
          Pay from Wallet
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid white",
          width: "200px",
          padding: "20px",
          borderRadius: "7px",
        }}
      >
        <span>You have to pay</span>
        <span
          style={{
            border: "1px solid white",
            width: "70px",
            textAlign: "center",
            borderRadius: "5px",
            color: "green",
          }}
        >
          {amount} ₹
        </span>
        <p>for this movie</p>
        <button
          style={{
            backgroundColor: "skyblue",
            borderRadius: "10px",
            padding: "2px 15px",
          }}
          onClick={() => handleVideoPayClick()}
        >
          Make Payment
        </button>
      </div>
    </div>
  );
}
