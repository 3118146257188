import React from "react";
import styles from "./CarouselItems.module.css";
import star from "../../assets/new-images/Home/star.png";
export const CarouselItems = ({ _id, genre, keyArt1, title }) => {
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <div className={styles.carousel_item}>
      <div className={styles.poster_container}>
        <img src={keyArt1?.file} />
      </div>
      <div className={styles.carousel_details_box}>
        {/* <p className={styles.carousel_details_title}>{title}</p> */}
        {/* <p>
          <span>{rating?.toFixed(1)}</span>
          <img src={star} alt="" />
        </p> */}
      </div>
      {/* <div>
        {genre?.map((el, i) => {
          return (
            <span key={i} className={styles.carousel_genres_item}>
              {el} {i == genre?.length - 1 ? "" : ","}
            </span>
          );
        })}
      </div> */}
    </div>
  );
};
