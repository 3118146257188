import React, { useRef, useState } from "react";
import Form from "../components/form/index";
import "./styles/contact.css";
import { Container } from "../components/form/styles/form";
import axios from "axios";
import { cmsBaseUrl } from "../Api-config/cms";
import ReCAPTCHA from "react-google-recaptcha";
import { baseUrl } from "../Api-config/auth";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { captchakey, generateTokenHandler } from "../utils/json-web";

export const Contact = () => {
  window.trackUser(null);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { name, email, message } = contactForm;
  const [status, setStatus] = useState(false);
  const recaptchaRef = useRef(null);
  const [show1, setShow1] = useState(false);
  const [msg, setMsg] = useState("");

  //  fn for sending the message
  const handleClick = async () => {
    let slug = await generateTokenHandler();

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    if (!captchaToken) {
      setShow1(true);
      setMsg("Please! verify reCaptcha");
      // setLoader(false)
      return;
    }
    const config = {
      url: baseUrl + "verifyCaptcha",
      method: "post",
      headers: {
        slug: slug,
      },
      data: {
        token: captchaToken,
      },
    };
    // axios call for google reCaptcha verification
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status) {
          if (response?.data?.data?.success) {
            console.log("recaptcha-verified", response.data);
          } else {
            setShow1(true);
            setMsg("Please! verify reCaptcha");
            return;
          }
        }
        if (!response?.data?.status) {
          setShow1(true);
          setMsg("Something went wrong");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        setShow1(true);
        setMsg("Please! verify reCaptcha");
        return;
      });

    if (name == "" || email == "" || message == "") {
      setStatus(false);
      setMsg("Please Fill all the credentials");
      setShow1(true);
      setTimeout(() => {
        setMsg("");
        setShow1(false);
      }, 3000);
      return;
    }
    let slug1 = await generateTokenHandler();

    const config_v = {
      url: cmsBaseUrl + "/api/consumerApi/contactUs",
      method: "post",
      headers: {
        slug: slug1,
      },
      data: contactForm,
    };
    // Api call
    axios
      .request(config_v)
      .then((re) => {
        setStatus(true);
        setMsg("Your message has been successfully submitted.");
        setShow1(true);
        setTimeout(() => {
          setMsg("");
          setStatus(false);
          setShow1(false);
        }, 4000);
        setContactForm({ name: "", email: "", message: "" });
      })
      .catch((err) => {
        setStatus(false);
        setMsg("Something went wrong.");
        setShow1(true);
        setTimeout(() => {
          setMsg("");
          setStatus(false);
          setShow1(false);
        }, 4000);
        setContactForm({ name: "", email: "", message: "" });
      });
  };

  return (
    <>
      <ScrollToTop />
      <div
        className="form-container"
        style={{
          textAlign: "center",
          position: "relative",
          // height: "70vh",
        }}
      >
        <Form.Title>Contact us 🌻</Form.Title>
        <Form.Text>Need Assistance? Contact us</Form.Text>

        <Form.Input
          placeholder="Enter Name"
          value={contactForm.name}
          onChange={(e) =>
            setContactForm({ ...contactForm, name: e.target.value })
          }
        ></Form.Input>
        <Form.Input
          placeholder="Enter Email"
          value={contactForm.email}
          onChange={(e) =>
            setContactForm({ ...contactForm, email: e.target.value })
          }
        ></Form.Input>
        <Form.TextArea
          required
          value={contactForm.message}
          placeholder="Enter your message here"
          rows="8"
          onChange={(e) =>
            setContactForm({ ...contactForm, message: e.target.value })
          }
        ></Form.TextArea>
        <ReCAPTCHA
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
            marginTop: "20px",
            width: "250px",
          }}
          ref={recaptchaRef}
          sitekey={captchakey}
        />
        <Form.Submit onClick={handleClick}> Send Message </Form.Submit>
        {show1 && (
          <Modal
            dialogclassname="modal-dialog modal-sm"
            show={show1}
            onHide={() => setShow1(false)}
          >
            <Modal.Body className="p-0">
              <div className="card logout-card common_box">
                <div className="card-body common_box_body ">
                  <p
                    className="modalTitle text-center mb-2 fw-bold"
                    style={{ color: `${status ? "green" : "red"}` }}
                  >
                    {msg}
                  </p>
                  <form className="custom_form">
                    <div className="btn-wrapper text-center">
                      <Link
                        className="round-modal-btn"
                        onClick={() => setShow1(false)}
                      >
                        ok
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
};
