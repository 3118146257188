import React, { useEffect, useState } from "react";
import styles from "./MediaPlayer.module.css";
export const Controls = ({ id = "videoPlayer", isplaying = false }) => {
  const video = document?.getElementById(id);
  const [counter, setCounter] = useState(0);
  const [isPlaying, setPlaying] = useState(isplaying);

  const onPlay = () => {
    console.log(video.paused);
    console.log("play");
    // video.play();
    setPlaying(true);
  };

  const onPause = () => {
    console.log("pause");
    // console.log(video.onplaying);
    // video.pause();
    setPlaying(false);
  };

  const onForward = () => {
    console.log("onForward");
  };

  const onBackward = () => {
    console.log("backward");
  };
  console.log(video?.paused);

  useEffect(() => {
    console.log("Current player status ", isPlaying);
    // if (counter == 0)
    //   setTimeout(() => {
    //     // onPlay();
    //     document.getElementById("play_button")?.click();
    //     setCounter(counter + 1);
    //   }, 3000);
  }, []);
  return (
    <div style={{ display: "none" }}>
      <div className={styles.timer_container}>
        <span id="current-time"></span>
        <span id="separator"></span>
        <span id="max-duration"></span>
      </div>
      {/* <div className={styles.progress_container}>
        <div id="progress-bar" className={styles.progress_bar}></div>
        <div className={styles.range_button} id="range-button"></div>
      </div> */}

      <div className={styles.icon_controles}>
        {!isPlaying ? (
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#50e4ad"
            
            width={"30px"}
            id="play_button"
            onClick={onPlay}
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 4.83164C4 3.24931 5.75049 2.29363 7.08152 3.14928L18.2323 10.3176C19.4569 11.1049 19.4569 12.8951 18.2323 13.6823L7.08152 20.8507C5.75049 21.7063 4 20.7506 4 19.1683V4.83164ZM17.1507 12L6 4.83164V19.1683L17.1507 12Z"
                fill="#00000"
              ></path>{" "}
            </g>
          </svg>
        ) : (
          <svg
            viewBox="0 0 24 24"
            width={"30px"}
            fill="none"
            id="pause_button"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#50e4ad"
            onClick={onPause}
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 5C4 3.89543 4.89543 3 6 3H9C10.1046 3 11 3.89543 11 5V19C11 20.1046 10.1046 21 9 21H6C4.89543 21 4 20.1046 4 19V5ZM9 5H6V19H9V5Z"
                fill="#00000"
              ></path>{" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 5C13 3.89543 13.8954 3 15 3H18C19.1046 3 20 3.89543 20 5V19C20 20.1046 19.1046 21 18 21H15C13.8954 21 13 20.1046 13 19V5ZM18 5H15V19H18V5Z"
                fill="#00000"
              ></path>{" "}
            </g>
          </svg>
        )}

        <svg
          viewBox="0 0 24 24"
          width={"30px"}
          fill="none"
          id="backward"
          onClick={onBackward}
          xmlns="http://www.w3.org/2000/svg"
          stroke="#50e4ad"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.57909 4.83828C10.5644 4.07194 12 4.77409 12 6.02231V12V17.9777C12 19.2259 10.5644 19.928 9.57909 19.1617L1.38606 12.7893C1.14247 12.5999 1 12.3086 1 12C1 11.6914 1.14247 11.4001 1.38606 11.2106L9.57909 4.83828ZM3.62882 12L10 16.9553V7.04463L3.62882 12Z"
              fill="#00000"
            ></path>{" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 12C12 12.3086 12.1425 12.5999 12.3861 12.7893L20.5791 19.1617C21.5644 19.928 23 19.2259 23 17.9777V6.02231C23 4.77409 21.5644 4.07194 20.5791 4.83828L12.3861 11.2106C12.1425 11.4001 12 11.6914 12 12ZM14.6288 12L21 16.9553V7.04463L14.6288 12Z"
              fill="#00000"
            ></path>{" "}
          </g>
        </svg>

        <svg
          viewBox="0 0 24 24"
          width={"30px"}
          onClick={onForward}
          fill="none"
          id="forward"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#50e4ad"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.42091 4.83828C2.43562 4.07194 1 4.77409 1 6.02231V17.9777C1 19.2259 2.43562 19.928 3.42091 19.1617L11.6139 12.7893C11.8575 12.5999 12 12.3086 12 12V17.9777C12 19.2259 13.4356 19.928 14.4209 19.1617L22.6139 12.7893C22.8575 12.5999 23 12.3086 23 12C23 11.6914 22.8575 11.4001 22.6139 11.2106L14.4209 4.83828C13.4356 4.07194 12 4.77409 12 6.02231V12C12 11.6914 11.8575 11.4001 11.6139 11.2106L3.42091 4.83828ZM9.37118 12L3 16.9553V7.04463L9.37118 12ZM20.3712 12L14 16.9553V7.04463L20.3712 12Z"
              fill="#00000"
            ></path>{" "}
          </g>
        </svg>
      </div>
    </div>
  );
};
