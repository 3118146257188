import { useState, useRef } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slideVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const SlideItem = ({ item }) => {
  console.log("item: ", item);
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverStart = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="slide-container"
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
    >
      <img src={item?.BackgroundImage?.file} className="slider_img_poster" />
      {isHovered && (
        <>
          <div className="slider_img_overlay">
            {/* Add additional data/content for the overlay */}
            <p>{item?.title}</p>
            <p>{item?.genre?.join(", ")}</p>
          </div>
          <div className="slider_movie_price">${item?.price}</div>
        </>
      )}
    </div>
  );
};
function SlickSlider({ movieData }) {
  console.log(movieData);
  const PreviousBtn = (props) => {
    const { style, className, onClick } = props;
    return (
      <div className={className} onClick={onClick} style={{ ...style }}>
        <p>
          <i
            className="fa-solid fa-chevron-right"
            style={{ ...style, color: "#ecedef" }}
          ></i>
        </p>
      </div>
    );
  };
  console.log(movieData, "sfdfjjjsdfjjsfdj");
  const NextBtn = (props) => {
    const { className, onClick, style } = props;
    return (
      <div className={className} onClick={onClick} style={{ ...style }}>
        <p>
          <i className={`fa-solid fa-chevron-left slick-custom-arrow`}></i>
        </p>
      </div>
    );
  };

  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    speed: 500,
    slidesToShow: 4,
    dots: false,
    infinite: true,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="silder_box">
      <Slider {...settings}>
        {movieData?.map((item, i) => (
          <div key={i}>
            <SlideItem item={item} key={item._id} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SlickSlider;
