import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { movieBaseUrl } from "../Api-config/movie";
import styles from "./styles/payment.module.css";

export default function PaymentPending() {
  const transactionId = sessionStorage.getItem("transactionId");
  console.log("the transaction Id stored is : ", transactionId);
  const token = localStorage.getItem("authUser");
  console.log("the token of the viewer is : ", token);

  const navigate = useNavigate();

  const checkStatusAPI = async () => {
    const config = {
      method: "post",
      url: movieBaseUrl + "check_status",
      headers: {
        Authorization: token,
      },
      data: {
        transactionId,
      },
    };
    const checkStatusResponse = await axios(config);
    console.log(
      "the value of the check status api response is : ",
      checkStatusResponse
    );
    if (
      checkStatusResponse.data.status &&
      checkStatusResponse.data.data.status
    ) {
      const responseStatus = checkStatusResponse.data.data.data;
      // here we have to handle to send the user to different pages according to the codes like -> PAYMENT_DECLINED , TIMED_OUT , BAD_REQUEST , AUTHORIZATION_FAILED , TRANSACTION_NOT_FOUND , etc
      if (
        responseStatus.success == true &&
        responseStatus.code == "PAYMENT_SUCCESS"
      ) {
        navigate("/paymentSuccess");
        return;
      } else if (
        responseStatus.success == false &&
        responseStatus.code == "PAYMENT_ERROR"
      ) {
        navigate("/paymentError");
        return;
      } else if (
        responseStatus.success == false &&
        (responseStatus.code == "BAD_REQUEST" ||
          responseStatus.code == "AUTHORIZATION_FAILED" ||
          responseStatus.code == "TRANSACTION_NOT_FOUND")
      ) {
        console.log("BAD REQUEST ");
        return;
      }
    }
    // console.log("This is the checkStatus API calling..............");

    const currentTime = new Date().getTime() / 1000; // Convert to seconds
    // console.log(
    //   ">>>>>>>>>>>>>>>the time interval for every call is : ",
    //   currentTime - startTime
    // );

    if (currentTime - startTime < 50) {
      callApiWithInterval(3000); // Every 3 seconds for the next 30 seconds
    } else if (currentTime - startTime < 110) {
      callApiWithInterval(6000); // Every 6 seconds for the next 60 seconds
    } else if (currentTime - startTime < 170) {
      callApiWithInterval(10000); // Every 10 seconds for the next 60 seconds
    } else if (currentTime - startTime < 230) {
      callApiWithInterval(30000); // Every 30 seconds for the next 60 seconds
    } else if (currentTime - startTime < 1355) {
      callApiWithInterval(60000); // Every 1 min until timeout (20 mins)
    }
  };

  const callApiWithInterval = (interval) => {
    setTimeout(() => {
      checkStatusAPI();
    }, interval);
  };

  useEffect(() => {
    window?.flutter_inappwebview?.callHandler("paymentPending", null);
    setTimeout(() => {
      checkStatusAPI();
    }, 20000);
  }, []);

  const startTime = new Date().getTime() / 1000; // Convert to seconds

  //   useEffect
  return (
    <div className={styles.payment_error}>
      <svg
        viewBox="0 0 1024 1024"
        class="icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="orange"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M511.9 183c-181.8 0-329.1 147.4-329.1 329.1s147.4 329.1 329.1 329.1c181.8 0 329.1-147.4 329.1-329.1S693.6 183 511.9 183z m0 585.2c-141.2 0-256-114.8-256-256s114.8-256 256-256 256 114.8 256 256-114.9 256-256 256z"
            fill="orange"
          ></path>
          <path
            d="M548.6 365.7h-73.2v161.4l120.5 120.5 51.7-51.7-99-99z"
            fill="orange"
          ></path>
        </g>
      </svg>
      <h1>Payment Pending</h1>
    </div>
  );
}
