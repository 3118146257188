import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Styles from "./Popuppage.module.css";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { movieBaseUrl } from "../../../Api-config/movie";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import Confetti from "react-confetti";

function Popuppage(props) {
  const { getDeviceType } = props;
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [fullName, setFullName] = useState("");
  // const [selectedGenre, setSelectedGenre] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState([]);
  const [isSeasonSelected, setIsSeasonSelected] = useState(false);

  const userAgent = window.navigator.userAgent.toLowerCase();

  // const [genreList, setGenreList] = useState([]);

  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }
    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"/g, ""); // Remove double quotes around values

      result[key] = value;
    });

    return result;
  }

  const authUser = localStorage.getItem("authUser");
  const navigate = useNavigate();

  // const categoryList = [
  //   { value: "movie", label: "Movies" },
  //   { value: "documentary", label: "Documentaries" },
  //   { value: "series", label: "Tv Series" },
  //   { value: "miniSeries", label: "Mini Series" },
  //   { value: "podcast", label: "Podcasts" },
  //   { value: "standUp", label: "Stand up" },
  // ];

  // const handleSelectGenre = (selectedOptions) => {
  //   // Limiting the number of selections for genreList to a maximum of 4
  //   if (selectedOptions.length <= 4) {
  //     setSelectedGenre(selectedOptions);
  //   } else {
  //     getToast("You can Select Max 4 Genre Preference", "top-right", "warn");
  //     return;
  //   }
  // };

  // const handleSelectCategory = (selectedOptions) => {
  //   // Limiting the number of selections for categoryList to a maximum of 4
  //   if (selectedOptions.length <= 4) {
  //     setSelectedCategory(selectedOptions);
  //   } else {
  //     getToast("You can Select Max 4 Category Preference", "top-right", "warn");
  //     return;
  //   }
  // };
  const handleChange = (event) => {
    event.target.style.color = "#111217";
    setFullName(event.target.value);
  };

  const getToast = (message, position, type) => {
    const obj = {
      item: message,
      tag: type === "success" ? "Success" : "Failure",
      className: type === "success" ? "success" : "error",
    };
    const notifications =
      JSON.parse(sessionStorage.getItem("notifications")) || [];

    if (notifications.length >= 20) {
      notifications.shift();
    }

    notifications.push(obj);
    sessionStorage.setItem("notifications", JSON.stringify(notifications));

    switch (type) {
      case "success":
        toast.success(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        break;
      case "error":
        toast.error(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        break;

      case "warn":
        toast.warn(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });

        break;

      case "info":
        toast.info(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });

        break;
      // Add more cases for other types as needed
      default:
        // Handle unknown types or provide a default behavior
        toast(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        break;
    }
  };

  const token = localStorage.getItem("token");
  const handleSubmit = async (onHide) => {
    // if()

    // const genreValues = selectedGenre.map((option) => option.value);
    // const categoryValues = selectedCategory.map((option) => option.value);
    // console.log(genreValues);
    // console.log(categoryValues);

    if (fullName.trim() == "") {
      console.log(
        "->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
        fullName.trim()
      );
      getToast("Enter Your Name", "top-right", "warn");
      return;
    }
    // if (selectedGenre.length == 0) {
    //   getToast("Select Genre Preference", "top-right", "warn");
    //   return;
    // }

    // if (selectedCategory.length == 0) {
    //   getToast("Select Category Preference", "top-right", "warn");
    //   return;
    // }

    const checkfullName = fullName.trim();

    // console.log("->>>>>>>>>>>>>>>>>>>>>>>>>>>>", userDetails)
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "addUserDetails",
      headers: headers,
      data: {
        fullName: checkfullName,
        // categoryPreference: categoryValues,
        // genrePreference: genreValues,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          if (response?.data?.response_code == 200) {
            getToast("Your Profile added Successfully", "top-right", "success");
            setIsConfettiActive(true);
            onHide();
            setTimeout(() => {
              setIsConfettiActive(false);
            }, 5000);
          }
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 422) {
            getToast(response?.data?.message, "top-right", "warn");
          }
          if (response?.data?.response_code == 401) {
            getToast(
              t("Your token is expired. Please login again."),
              "top-right",
              "error"
            );
            setTimeout(() => {
              localStorage.clear();
              navigate(ROUTES.SIGN_IN);
            }, 3000);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
          setTimeout(() => {
            localStorage.clear();
            navigate(ROUTES.SIGN_IN);
          }, 3000);
        } else {
          getToast(t("Something went wrong"), "top-right", "error");
        }
      });
  };

  // const getgenreList = async (onHide) => {
  //   let headers = {
  //     authorization: authUser,
  //     devicetype: getDeviceType(),
  //   };
  //   if (
  //     deviceDetailsFromApp &&
  //     (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
  //   ) {
  //     headers.appid =
  //       deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
  //   }
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: movieBaseUrl + "getGenreList",
  //     headers: headers,
  //   };

  //   axios
  //     .request(config)
  //     .then((res) => {
  //       // console.log("res: ", res.data.data);

  //       // if (res.data.status) {
  //       const genresFromBackend = res.data.data;
  //       const formattedGenres = genresFromBackend.map((genre) => ({
  //         value: genre,
  //         label: genre,
  //       }));

  //       setGenreList(formattedGenres);
  //       // }
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.response_code == 401) {
  //         navigate("/signin");
  //         localStorage.clear();
  //         getToast(
  //           t("Your token is expired. Please login again."),
  //           "top-right",
  //           "error"
  //         );
  //       } else {
  //         getToast(err?.response?.data?.message, "top-right", "error");
  //       }
  //     });
  // };

  // useEffect(() => {
  //   getgenreList();
  // }, []);
  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        centered
        className={Styles.ModalFooterWithoutLine}
      >
        <Modal.Header className={Styles.modal_header}>
          <Modal.Title>{t("Welcome User")}</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className={Styles.modal_body}> */}
        <form className={Styles.launched_form}>
          <Row className={Styles.form_row}>
            <div className={Styles.input_container}>
              <label className={Styles.detail_label}>
                {t("Enter Your Full Name")}
              </label>
              <input
                type="text"
                name="fullName"
                onChange={handleChange}
                placeholder="Enter your name"
                className={Styles.detial_input}
              />
            </div>
          </Row>

          {/* <Row className={Styles.form_row}>
            <div className={Styles.input_container}>
              <label className={Styles.detail_label}>
                {t("Genre Preference")}
              </label>
              <Select
                className={Styles.createable_input}
                options={genreList}
                onChange={handleSelectGenre}
                value={selectedGenre}
                isSearchable={true}
                isMulti
                required
                isDisabled={isSeasonSelected}
              />
            </div>
          </Row>

          <Row className={Styles.form_row}>
            <div className={Styles.input_container}>
              <label className={Styles.detail_label}>
                {t("Category Preference")}
              </label>
              <Select
                className={Styles.createable_input}
                options={categoryList}
                onChange={handleSelectCategory}
                value={selectedCategory}
                isSearchable={true}
                isMulti
                required
                isDisabled={isSeasonSelected}
              />
            </div>
          </Row> */}
        </form>
        {/* </Modal.Body> */}
        <Modal.Footer className={Styles.modal_fotter}>
          <button
            className={Styles.detail_save_btn}
            onClick={() => handleSubmit(props.onHide)}
          >
            {t("Save")}
          </button>
        </Modal.Footer>
      </Modal>
      {isConfettiActive && <Confetti recycle={false} />}
    </>
  );
}

export default Popuppage;
