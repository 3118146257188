import React, { useState } from "react";
import EpisodeCard from "./components/EpisodeCard";
import styles from "./SeriesEpisodesList.module.css";
import SeriesHeader from "./SeriesHeader";
import { useTranslation } from "react-i18next";

const SeriesEpisodesList = ({
  getPaymentStatus,
  cbfc_Rating,
  detailedData,
  getToast,
  getDeviceType,
  deviceDetailsFromApp,
  paymentStatus,
  seasonNumber,
  setSeasonNumber
}) => {
  // State to track the selected season; default is the first season
  const [selectedSeason, setSelectedSeason] = useState(1);
  const { t } = useTranslation();

  // State to keep track of the number of episodes currently visible; start with 10
  const [visibleEpisodes, setVisibleEpisodes] = useState(10);

  // Total number of seasons available in the detailedData prop, default is 1 if not present
  const totalSeason = detailedData?.seasons?.length || 1;
  // const totalSeason =  5;

  // Helper function to format duration in hours and minutes
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60); // Convert seconds to minutes
    return `${minutes} min`;
  };

  // Function to load more episodes; increases visible episodes by 10 each time it's called
  const loadMoreEpisodes = () => {
    setVisibleEpisodes((prevVisibleEpisodes) => prevVisibleEpisodes + 10);
  };

  return (
    <div className={styles.container}>
      {/* Header component for season selection */}
      <SeriesHeader
        seasonsNumber={seasonNumber}
        totalSeason={totalSeason}
        setSeasonsNumber={setSeasonNumber}
      />
      <div className={styles.episodesContainer}>
        {detailedData?.seasons[seasonNumber - 1]?.episodes
          ?.sort((a, b) => a.episodeNumber - b.episodeNumber) // Sort episodes by episode number
          .slice(0, visibleEpisodes) // Show only the number of episodes defined in visibleEpisodes
          .map((episode, index) => (
            <EpisodeCard
              key={episode.id || index} // Unique key for each episode
              cbfc_Rating ={cbfc_Rating}
              imageSrc={episode.thumbnail?.file} // Episode thumbnail image
              title={`S${seasonNumber} E${episode.episodeNumber} - ${episode.title}`} // Episode title with season and episode number
              releaseDate="October 23, 2020" // Static release date (you may replace this with actual data if available)
              duration={formatDuration(episode.duration)} // Formatted duration of the episode
              episode={episode?.episodeNumber} // Episode number
              seasonNumber={seasonNumber} // Season number
              description={episode.synopsis} // Episode synopsis
              cast={episode?.cast} // Episode cast details
              crew={episode?.crew} // Episode crew details
              isFreeEpisode // Boolean flag for free episodes
              detailedData={detailedData} // Full series details passed to EpisodeCard
              getToast={getToast} // Function for displaying toast notifications
              deviceDetailsFromApp={deviceDetailsFromApp} // Device details from app
              paymentStatus={paymentStatus} // Payment status for episode access
              getDeviceType={getDeviceType} // Function to get device type information
            />
          ))}
      </div>
      {/* Show "Show More" button only if there are more episodes to load */}
      
      {visibleEpisodes < detailedData?.seasons[seasonNumber - 1]?.episodes.length && (
        <div className={styles.showMoreButtonContainer}>
        <button className={styles.showMoreButton} onClick={loadMoreEpisodes}>
        { t("Show More Episodes")}
        </button>
          </div>
      )}
    </div>
  );
};

export default SeriesEpisodesList;
