import React, { createContext, useContext, useState } from "react";

const RedirectContext = createContext();

export const RedirectProvider = ({ children }) => {
  const [redirectPath, setRedirectPath] = useState("/");

  const resetRedirectPath = () => setRedirectPath("/");

  localStorage.setItem("is_redirect", "yes");

  return (
    <RedirectContext.Provider
      value={{ redirectPath, setRedirectPath, resetRedirectPath }}
    >
      {children}
    </RedirectContext.Provider>
  );
};

export const useRedirect = () => useContext(RedirectContext);
