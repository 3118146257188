import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles/inviteUser.css";
import { baseUrl } from "../Api-config/auth";

import { useTranslation } from "react-i18next";
import { generateTokenHandler } from "../utils/json-web";

export default function InviteUsers({ getToast, getDeviceType }) {
  let [rc, setRc] = useState("");
  const [allReferredUser, setAllReferredUser] = useState([]);
  const [msg, setMsg] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [inputFields, setInputFields] = useState([
    { email: "", isValid: true },
  ]);
  const { t } = useTranslation();
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }
    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0].trim();
      var value = parts[1].trim().replace(/"/g, ""); // Remove double quotes around values

      result[key] = value;
    });

    return result;
  }

  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index].email = event.target.value;
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([{ email: "", isValid: true }, ...inputFields]);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const sendInvite = async () => {
    if (inputFields.length === 1 && inputFields[0].email?.trim() === "") {
      setMsg("*Email is required");
      setTimeout(() => {
        setMsg("");
      }, 4000);
    } else {
      const emails = inputFields.filter((obj) => obj.email?.trim() !== "");
      setInputFields(emails);

      const token = localStorage.getItem("authUser");
      let slug = await generateTokenHandler();

      let headers = {
        authorization: token,
        devicetype: getDeviceType(),
        "Content-Type": "application/json",
        slug: slug,
      };
      if (
        deviceDetailsFromApp &&
        (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
      ) {
        headers.appid =
          deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: baseUrl + "api/viewerApp/sendInvites",
        headers: headers,
        data: { rc: rc, emails: inputFields },
      };
      axios
        .request(config)
        .then((response) => {
          console.log(response.data["Invalid-emails"]);
          let inv = response.data["Invalid-emails"];
          inputFields.map((email, index) => {
            if (inv?.includes(email.email)) {
              if (email.email !== "") {
                const values = [...inputFields];
                values[index].isValid = false;
                setInputFields(values);
              }
            } else {
              const values = [...inputFields];
              values[index].isValid = "succ";
              setInputFields(values);
            }
            if (inv?.length === inputFields?.length)
              return setMsg("*Please provide valid emails");
            else if (inv?.length === 0) {
              getToast(t("Send Successfully"), "top-right", "success");
              setMsg("Sent successfully");
            } else return setMsg("*Red marked emails are invalid");
          });
          setTimeout(() => {
            setMsg("");
          }, 5000);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(async () => {
    const refCode = localStorage.getItem("referralCode");
    const token = localStorage.getItem("authUser");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: getDeviceType(),
      "Content-Type": "application/json",
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    setRc(refCode);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: baseUrl + `api/viewerApp/getReferredUsers?rc=${refCode}`,
      headers: headers,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response?.data?.status === true) {
          if (response?.data?.response_code === 200)
            setAllReferredUser(response?.data?.data?.data);
        }
        if (response?.data?.status === false) {
          if (response?.data?.response_code === 404)
            setAllReferredUser(response?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.origin + `/signup?rc=${rc}`)
      .then(() => {
        console.log("Text copied to clipboard");
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  return (
    <div className="referal-header-box">
      <div className="referral-header">
        {/**for header it must be fixed */}
        <div className="heading-bar" style={{ margin: "0px 0px 0px" }}>
          <div className="head">
            <h1>Referral program</h1>
            <span>Refer a user and get a chance to win big</span>
          </div>
        </div>
      </div>

      <div className="refer">
        <div className="makeflex center">
          <div className="makeflex center cpy">
            <div className="bor">
              <h4 style={{ textAlign: "left" }}>Your referral link</h4>
            </div>
            <div className="bor">
              <input
                className="refCodeInp"
                type="text"
                readOnly={true}
                value={window.location.origin + `/signup?rc=${rc}`}
              ></input>
            </div>
            <div className="bor" style={{ position: "relative" }}>
              <p className="copybtn" onClick={handleCopy}>
                COPY LINK
              </p>
              {tooltipVisible && (
                <span
                  style={{
                    color: "black",
                    position: "absolute",
                    bottom: "0",
                    padding: "0px 8px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    marginLeft: "1%",
                    backgroundColor: "rgb(202, 201, 201)",
                  }}
                >
                  Copied
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="refer-by-email">
          {/**for invites */}
          <div className="makeflex" style={{ justifyContent: "center" }}>
            <div
              className="makeflex"
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <div className="email-width">
                <div style={{ overflow: "auto", maxHeight: "11rem" }}>
                  {inputFields.map((inputField, index) => {
                    return (
                      <div key={index} className="take-email">
                        <input
                          type="email"
                          value={inputField.email}
                          onChange={(event) => handleChange(index, event)}
                          placeholder="Email"
                          style={{
                            color: `${inputField.isValid ? "black" : "red"}`,
                            border: "none",
                            outline: "none",
                            width: "100%",
                            padding: "6px 12px",
                          }}
                          required
                        />
                        {inputFields.length !== 1 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "4%",
                              background: "white",
                            }}
                          >
                            {inputField.isValid === "succ" ? (
                              <i
                                className="far fa-check-circle"
                                style={{ color: "#0d8604" }}
                              />
                            ) : (
                              // :<img src={remove} style={{ height: "20px", marginRight: "3%" }} onClick={() => handleRemoveFields(index)} />}
                              <i
                                className="far fa-times-circle"
                                style={{ color: "black" }}
                                onClick={() => handleRemoveFields(index)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {msg !== "" && <span>{msg}</span>}
              </div>
              <div className="form-group">
                <button
                  className="round-btn invite-btn"
                  type="button"
                  onClick={() => handleAddFields()}
                >
                  Add <span>more</span>
                </button>
              </div>
              <div className="form-group">
                <button className="round-btn invite-btn" onClick={sendInvite}>
                  Invite
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="makeflex"
        style={{ alignItems: "center", marginTop: "3%" }}
      >
        <div className="referraltable">
          <div>
            {/**for invited person list */}
            <h3>List of invited people</h3>
            <div>
              {typeof allReferredUser === "string" ? (
                <span>{allReferredUser}</span>
              ) : (
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="underline">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allReferredUser?.map((user, index) => (
                      <tr key={index}>
                        <td>{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
