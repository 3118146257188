import React, { useEffect, useState } from "react";
import styles from "./Browse.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { HomeBanner } from "../../components/Banner/HomeBanner";
import { Carousel } from "../../components/Carousels/Carousel";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { movieBaseUrl } from "../../Api-config/movie";
import Popuppage from "./Pop_up_page/Popuppage.jsx";
import { FullScreenLoader } from "../../components/fullScreen loader/FullScreenLoader";
import comming_soon from "../../assets/new-images/Designer.png";
import ModalBody from "react-bootstrap/esm/ModalBody";
import { Col, Modal } from "react-bootstrap";
import { useAuthListener } from "../../hooks";
import { useRedirect } from "../../helpers/redirect_route";
import { generateTokenHandler } from "../../utils/json-web";
const Browse = ({ getToast, getDeviceType }) => {
  const { t } = useTranslation();
  const { category_slug } = useParams();
  const [showCommingSoon, setCommingSoon] = useState(false);
  const user = useAuthListener();
  const { redirectPath, resetRedirectPath } = useRedirect();

  const [whatsNew, setWhatsNew] = useState([]);
  const [trendingVideo, setTrendingVideo] = useState([]);
  const [suggestedVideo, setSuggestedVideo] = useState([]);
  // get auth token
  const authUser = localStorage.getItem("authUser");
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [continueWatching, setContinueWatching] = useState([]);
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );

  // handle chnage for deeplink

  const goBack = () => {
    setCommingSoon(false);
    navigate("/browse/all"); // This will go back one step in the browser history
  };
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }

  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  // continue wathcing
  const continueWathcing = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "getRatingMovieList",
      headers: headers,
    };
    console.log("config: ", config);

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        setLoader(false);
        if (res.data.status) {
          setContinueWatching(res?.data?.data);
        } else {
          setContinueWatching([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          setContinueWatching([]);
        }
      });
  };
  // get the new content
  const getWhatsNew = async () => {
    let url = `getNewVideos_v2`;
    let slug = await generateTokenHandler();
    url = url + `?category=${category_slug}`;

    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,

      url: movieBaseUrl + url,
      headers: headers,
    };
    console.log("config: ", config);

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        setLoader(false);
        if (res.data.status) {
          setWhatsNew(res?.data?.data?.videos);
          // getToast("Bela Ciao", "top-right", "error");
        } else {
          setWhatsNew([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          // getToast(err?.response?.data?.message, "top-right", "error");
          getToast(`No Results Found`, "top-right", "error");
          setWhatsNew([]);
        }
      });
  };
  // get the trending content
  const getTrendingContent = async () => {
    let url = `getTrendingVideos_v2`;
    let slug = await generateTokenHandler();
    url = url + `?category=${category_slug}`;
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + url,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status) {
          setTrendingVideo(res?.data?.data?.videos);
        } else {
          setCommingSoon(true);
          setTrendingVideo([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          // getToast(err?.response?.data?.message, "top-right", "error");
          getToast(t(`No Results Found`), "top-right", "error");
          setTrendingVideo([]);
        }
      });
  };
  // get the suggested video
  const getSuggestedVideo = async () => {
    let url = `suggestedVideoForViewer`;

    url = url + `?category=${category_slug}`;
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + url,
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status) {
          setSuggestedVideo(res?.data?.data?.videos);
        } else {
          setSuggestedVideo([]);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  const getUserDetails = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "getUserDetails",
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);

        console.log("->>>>>>>>>>>>>>>>>>>.", res.data.data.isProfileCompleted);
        if (res.data.status) {
          localStorage.setItem("fullName", res.data.data.fullName);
          localStorage.setItem("viewerId", res.data.data._id);
          const messageData = {
            authUser,
            viewerId: res.data.data._id,
            deviceType: getDeviceType(),
          };
          // send viewerId and authToken to app
          window?.flutter_inappwebview?.callHandler("authDetails", messageData);
          if (res.data.data.isProfileCompleted) {
            setModalShow(false);
          } else {
            setModalShow(true);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  useEffect(() => {
    getUserDetails();
    const isredirect = localStorage.getItem("is_redirect") == "yes";
    if (user && isredirect) {
      console.log("redirectPath: ", redirectPath);
      localStorage.setItem("is_redirect", "no");
      // resetRedirectPath(redirectPath);
      navigate(redirectPath);

      // <Navigate to={redirectPath} replace />;
    }
  }, []);

  useEffect(() => {
    getWhatsNew();
    continueWathcing();
    getSuggestedVideo();
    getTrendingContent();
  }, [category_slug]);

  const [modalShow, setModalShow] = useState(false);
  return loader ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <FullScreenLoader />
    </div>
  ) : (
    <>
      <div className={styles.browse_container}>
        {/* banner  */}
        <HomeBanner getToast={getToast} getDeviceType={getDeviceType} />

        <br />
        <br />
        {/* PopupPage */}
        <Popuppage
          show={modalShow}
          onHide={() => setModalShow(false)}
          getDeviceType={getDeviceType}
        />

        {/* continue watching*/}
        {/* <Carousel title={t("Continue Watching")} data={trendingVideo} /> */}
        {continueWatching?.length ? (
          <Carousel
            getToast={getToast}
            title={t("Continue Watching")}
            data={continueWatching}
            getDeviceType={getDeviceType}
          />
        ) : (
          ""
        )}

        {/* what's new  */}
        <Carousel
          getToast={getToast}
          title={t("What's New")}
          data={whatsNew}
          getDeviceType={getDeviceType}
        />

        {/* trending now */}
        <Carousel
          getToast={getToast}
          title={t("Trending Now")}
          data={trendingVideo}
          getDeviceType={getDeviceType}
        />

        {/* suggested for you   */}
        <Carousel
          getToast={getToast}
          title={t("Suggested for you")}
          data={suggestedVideo}
          getDeviceType={getDeviceType}
        />
      </div>

      {/* prefere */}
      <Modal
        show={showCommingSoon}
        centered
        onHide={() => setCommingSoon(false)}
      >
        <Modal.Header className={styles.coming_soon_modal}>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <svg
              viewBox="-0.5 0 25 25"
              fill="none"
              style={{ cursor: "pointer" }}
              width={"20px"}
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => goBack()}
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M3 21.32L21 3.32001"
                  stroke="#50e4ad"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
                <path
                  d="M3 3.32001L21 21.32"
                  stroke="#50e4ad"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </Col>

          <div className={styles.comming_soon_container}>
            <img src={comming_soon} alt="" />
          </div>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default Browse;
