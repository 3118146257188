import React from "react";
import { Container, SubTitle, Title } from "../components/legalNotice";

export const Legalnotices = () => {
  window.trackUser(null);

  return (
    // <div>legalNotice</div>
    <Container>
      <h1
        style={{
          padding: "30px",
        }}
      >
        Legal Notices
      </h1>
      <p style={{ paddingLeft: "30px" }}>
        The Hall IV service, including all content provided on the Hall IV
        service, is protected by copyright, trademark, trade secret or other
        intellectual property laws and treaties.
      </p>

      <Container>
        <Title>Copyright</Title>
        <div style={{ marginTop: "20px" }}>
          <SubTitle>
            The copyrights in the shows and movies on our service are owned by
            many great producers, including Hall IV Studios, LLC. If you believe
            your or someone else’s copyrights are being infringed upon through
            the Hall IV service, let us know by completing the Copyright
            Infringement Claims form.
          </SubTitle>
        </div>
      </Container>

      <Container>
        <Title>Trademarks</Title>
        <div style={{ marginTop: "20px" }}>
          <SubTitle>
            Hall IV and the IV Logo are trademarks of Boxoffice Technologies
            Private Limited.
          </SubTitle>
          <SubTitle>
            If you haven’t received our permission, do not use the Hall IV marks
            as your own or in any manner that implies sponsorship or endorsement
            by Hall IV.
          </SubTitle>
          <SubTitle>
            A product branded with the Hall IV name or logo is a reflection of
            Hall IV. Unless you are one of our licensees, we don’t allow others
            to make, sell, or give away anything with our name or logo on it.
          </SubTitle>
        </div>
      </Container>

      <Container>
        <Title>Patents</Title>
        <div style={{ marginTop: "20px" }}>
          <SubTitle>
            Hall IV streaming and DVD services are covered by patents. For
            information on patents related to our services.
          </SubTitle>
        </div>
      </Container>

      <Container>
        <Title>Third Party Notices</Title>
        <div style={{ marginTop: "20px" }}>
          <SubTitle>
            The software installed on Hall IV ready devices to enable streaming
            of the Hall IV service may contain software available under open
            source or free software licenses (“Open Source Software”) and/or
            commercial software licensed to Hall IV by parties (“Commercial
            Software”). The Hall IV Terms of Use do not alter any rights or
            obligations you may have under those Open Source Software licenses.
            Additional information about Open Source and Commercial Software,
            including required acknowledgements, license terms and notices, can
            be found here.
          </SubTitle>
        </div>
      </Container>
    </Container>
  );
};
