import React from "react";
import styles from "./styles/privacy.module.css";
export const Privacy = () => {
  return (
    <div className={styles.privacy_container}>
      <h4 className={styles.header_text}>Privacy Policy</h4>
      <p>
        The on-demand digital video streaming service/ platform currently
        branded as ‘HallIV’ (“Platform”) which is owned, operated and managed by
        Boxofic Technologies Private Limited (“Boxofic”) that is being accessed
        and used by you, the user (“you/ your/ User”) of the Platform, is
        committed to protecting the privacy of its Users.
      </p>

      <br />
      <p>
        By accessing the Platform, you signify your agreement to accept this
        privacy policy (“Policy”). If you do not agree with any of the following
        terms of this Policy, please do not access the Platform and/ or use its
        services.
      </p>

      <br />

      <p>
        This Policy is subject to change/ amendments/ modifications/ alterations
        based on changes in the legal requirements, business, regulatory changes
        and the updated Policy shall be accessible to you on the Platform and
        shall be effective from the moment of availability of such updated
        Policy on the Platform, from time to time. Your continued use of the
        Platform pursuant to any change in the Policy shall be deemed an
        acceptance of the Policy. The Platform hereby encourages the User to
        view the Policy periodically to keep updated with any changes made to
        the Policy. Any such change in the Policy which is deemed material shall
        be communicated to you via prominent notices or other appropriate means
        at the sole discretion of the Platform.
      </p>
      <br />

      <p>
        This Policy explains how and why the Platform uses personal data [also
        known as personal information and/ or personally identifiable
        information (“PII”)]. Examples of PII are as follows: (i) passwords;
        (ii) financial information such as bank account or credit card or debit
        card or other payment instrument details; (iii) physical, physiological
        and mental health condition; (iv) sexual orientation; (v) medical
        records and history; (vi) biometric information; and (vii) any national
        methods of identification.
      </p>

      <p>
        However, the User must note that any information that is freely
        available or accessible in public domain or furnished under the Right to
        Information Act, 2005 or any other law for the time being in force shall
        not be regarded as PII for the purposes of this Policy and the Platform
        shall not be obliged to take any measures to protect any information
        that is freely available in the public domain.
      </p>

      <ol>
        <li>
          The Platform does not knowingly collect PII from individuals who are
          under the minimum required ages specified herein. You must be at least
          16 years old or the age of majority in your jurisdiction, whichever is
          greater, to use the Platform. Individuals under the applicable age may
          use the Platforms only through a parent or legal guardian's account
          and with their involvement. If you are a parent or legal guardian who
          believes your child has provided PII to the Platform without your
          consent, you may ask the Platform to delete such information by
          contacting the Platform at support@halliv.com.
        </li>
        <li>
          Collection: What kind of data/ PII is collected?
          <ol>
            <li>
              Account information/ information provided by you – The information
              provided by you while creating an account to access the Platform
              as provided in the HALLIV Terms of Service/User Agreement,
              including but not limited to your registered email address,
              payment method(s), PII, etc., and/ or data provided by you/
              volunteered to be provided by you while accessing and using the
              Platform may be collected by the Platform.
            </li>
            <li>
              Automatic information – Any and all kind of information that the
              Platform deems vital during your access to the Platform including
              but not limited to the time spent watching a certain genre of
              Content (defined below) on the Platform, time spent by you on the
              Platform, details of your interaction with support (as available
              on the Platform), device IDs or other unique identifiers of your
              device/ browser and network, etc., may be collected by the
              Platform to further optimize your use of the Platform and for the
              purpose of improving the Platform. For the purposes of this Policy
              and the Platform, “Content” collectively, shall mean audiovisual
              content including videos, audio (for example music and other
              sounds), graphics, photos, audio, video, text (such as comments
              and scripts), branding (including trade names, trademarks, service
              marks, or logos), software, metrics, and other materials.
            </li>
            <li>
              Information from affiliates – The Platform may collect information
              from third party companies that you are in association with/ in
              affiliation with/ in a relationship with (“Affiliates”). These
              Affiliates may include your internet service provider, mobile
              phone carriers, other companies who provide services to you in
              relation with the Platform (payment gateways, internet browsers),
              etc. Such information collected by Affiliates would include: (i)
              search queries and commands applicable to the Platform made
              through Affiliate devices or voice assistants; (ii) device IDs and
              unique identifiers like user interface information, the Platform
              registration user experience, etc.; and (iii) Platform activation
              system including but not limited to contact information.{" "}
            </li>
            <li>
              Other information: Any and all information collected from other
              sources in relation to the Platform including but not limited to
              (i) service providers that help the Platform determine a location
              based on your IP address in order to customize the Platform for
              you and for any other uses consistent with this Policy; (ii)
              security service providers that provide the Platform with
              information to secure the Platform’s systems, prevent fraud and
              help the Platform protect the security of the Users accounts;
              (iii) payment service providers that provide the Platform with
              payment or balance information, or updates to that information,
              based on their relationship with you; or (iv) publicly-available
              sources such as publicly available posts on social media platforms
              and information available through public databases associating IP
              addresses with internet service providers (ISPs). Please note that
              the sources of the aforementioned information are varying, from
              time to time.{" "}
            </li>
          </ol>
        </li>
        <li>
          THE PLATFORM SHALL NEVER MANDATE YOU TO PROVIDE YOUR PII/ SENSITIVE
          INFORMATION/ FINANCIAL INFORMATION/ HEALTH INFORMATION TO THE PLATFORM
          OR TO ANY AFFILIATE/ ANY ENTITY REPRESENTING THE PLATFORM. ANY
          DISCLOSURE OF SENSITIVE PERSONAL INFORMATION/ DATA SHALL BE AT THE
          SOLE RISK OF THE USER AND WITHOUT ANY LIABILITY TO THE PLATFORM
          (INCLUDING ITS ASSIGNEES, LICENSEES, SUB-LICENSEES, AFFILIATES,
          OFFICERS, DIRECTORS, AND EMPLOYEES). You understand, acknowledge and
          agree that the Platform or any other person/ entity acting on behalf
          of the Platform shall not in any manner be responsible for the
          authenticity of the sensitive personal information, or personal data
          or any other sensitive information provided by you to the Platform.
          The Platform shall not be liable or shall not be held responsible for
          any damage, loss, misuse of your data due to any reason attributable
          to a force majeure event.
        </li>
        <li>
          Please note that the Platform or any entity representing the Platform
          shall in no event ask for your sensitive information including
          financial information like debit card/ credit card number, financial
          pin numbers, etc. In the event of your access to the Platform requires
          any type of payment service(s), the information related to such third
          party service/ payment gateway shall be collected by such third party
          services and/ or payment gateway engaged by the Platform to facilitate
          such use by you of the Platform and the such data shall not be stored
          by the Platform in any event save and except only to the extent
          necessary. Since such private information shall be processed and
          accessed by such third party payment aggregator, the User is
          encouraged to read the privacy policies of such third party services.
          You acknowledge and agree that the Platform shall not be liable for
          any collection, access and processing of data by the payment gateway.
          Similarly, where you pay a third party application for your
          subscription package as part of an in-app or bundled purchase your
          payment information is directly collected and controlled by that third
          party. The only data collection by the Platform from the payment
          aggregator of data shall be to send a notification to the User that
          payment has been processed and the Content is available to watch for
          the User, save and except such data collection, the Platform disclaims
          all liabilities in respect of payment to access the Platform. You
          agree and acknowledge that the Platform shall not be liable or shall
          not be held responsible for any losses whatsoever, whether direct or
          indirect, consequential, incidental, special, including without
          limitation any losses due to delay in payment processing, payment
          fraud and/ or credit card, debit card fraud.{" "}
        </li>
        <li>
          Use of information: The Platform uses information to provide, analyze,
          administer, enhance and personalize the services and marketing
          efforts, to process your registration, your orders for Digital Rental
          and your payments, and to communicate with you on these and other
          topics. For example, the Platform uses such information to:
          <ol>
            <li>
              help the Platform to quickly and efficiently respond to inquiries
              and requests;
            </li>
            <li>
              provide you with customized and personalized viewing
              recommendations for Content including localized Content;
            </li>
            <li>
              understand the Platform’s audience, improve the services offered
              by the Platform (including the Platform’s user interface
              experiences) and optimize content selection, recommendation
              algorithms and delivery by methods such as monitoring your
              activity on the Platform including titles viewed, load times,
              watch times, content selection and watch history, transactions
              initiated and/or completed, etc.;
            </li>
            <li>
              determine your general geographic location (such as your city,
              state/province, and postal code);
            </li>
            <li>
              determine your ISP to support network troubleshooting for you;
            </li>
            <li>
              coordinate with Affiliates on making the Platform available to
              members of the Platform and providing information to non members
              about the availability of the Platform, based on the specific
              relationship you have with the Affiliate
            </li>
            <li>
              secure the Platform’s systems, prevent fraud and help the Platform
              protect the security of the Platform’s accounts;
            </li>
            <li>
              prevent, detect and investigate potentially prohibited or illegal
              activities, including fraud, and to enforce the Platform’s terms
              (such as determining whether and for which signup offers you are
              eligible and determining whether a particular device is permitted
              to use the account consistent with the Platform’s Terms of Use).
              For the purposes of the Policy and the Platform, “Terms of Use”
              shall consist of the user agreement, terms of services, this
              Policy, copyright policy and any other guidelines (legal or
              otherwise) available on the Platform and as applicable to you
            </li>
            <li>
              communicate with you concerning the Platform so that the Platform
              can send you news about the Platform, details about new features
              and content available on the Platform, special offers, consumer
              surveys, and to assist you with operational requests such as
              password change requests. These communications may be by various
              methods, such as email, push notifications, text message, online
              messaging channels such as email.
            </li>
            <li>
              complete your Account creation/ registration processes, including
              providing One Time Password (OTP) on your registered mobile number
              for activating and/ or logging-in your Account on the Platform.
            </li>
            <li>
              to gather demographic and device information about the user base
              as a whole. The Platform may use this information in its
              advertising services. The Platform may also use such information
              to measure sales and traffic patterns on the Platform, advertising
              interactions and effectiveness and advertisement identifiers and
              general enhancement of the Platform.
            </li>
            The Platform’s content recommendations system strives to predict
            what you will be in the mood to watch when you log in. However, the
            content recommendations system does not infer or attach
            socio-demographic information (like gender or race) to you.
          </ol>
        </li>

        <li>
          Collection of information: The Platform may use certain methods to
          collect information about you subject to the device and/ or the
          browser you access the Platform with/ by and at the Platform’s sole
          discretion. Electronic identifiers refer to unique digital information
          that may be associated with your device and/or your browser when you
          access the Platform (“Electronic Identifiers”).
          <ol>
            <li>
              The Electronic Identifiers shall include but not limit (i) cookies
              that are small text files that are stored on your device
              (computer, smartphone, tablet, etc.,) when you visit/ use/ access
              the Platform (“Cookies”); and (ii) other device/ electronic
              identifiers may include pixel tags, web beacons, local storage,
              and similar technologies that perform similar functions. These
              Cookies contain information that allows the Platform to recognize
              your device/ browser during subsequent visits.
            </li>
            <li>
              . Such Electronic Identifiers may also store data on your device
              and/ or the browser. Such methods shall/ may attach a unique
              identification (“User ID”) to the User and unless the User ID is
              voluntarily identified by you using your registration data, the
              Platform will be unaware of your identity at all times. Third
              party companies may place cookies/ electronic identifiers to their
              advertisements which is beyond the control of the Platform and the
              Platform disclaims any damages/ losses arising due to your
              clicking of such advertisements. The Platform may place temporary
              or permanent Electronic Identifiers in your device(s). You have
              the option to manage and control the acceptance of Electronic
              Identifiers through your browser or device settings, the
              consequences of such management of Electronic Identifiers
              including Cookies shall vary at the sole discretion of the
              Platform. Most web browsers allow you to control the use of
              cookies and other similar technologies, including web beacons and
              pixel tags
            </li>
            <li>
              Choosing Electronic Identifiers:
              <ul>
                <li>
                  To manage Cookies: You may choose to prevent installation of
                  Cookies through your browser settings and configure your
                  browser to set your cookie preferences. At this time, the
                  Platform do not respond to web browser "do not track" signals.
                </li>
                <li>
                  .To manage the resettable device identifiers: To opt out of
                  behavioural ads from the Platform in connection with a
                  resettable device identifier on a mobile device, tablet, or
                  streaming media devices, please configure the appropriate
                  setting on your device in the device settings. You may still
                  see the Platform ads on your device, but they will not be
                  selected based on your likely interests.
                </li>
                <li>
                  To manage other technologies: There are a number of ways to
                  exercise choice regarding technologies that are similar to
                  cookies, such as browser storage and plugins (e.g., HTML5,
                  IndexedDB, and WebSQL). For example, many popular browsers
                  provide the ability to clear browser storage, commonly in the
                  settings or preferences area. The users are recommended to see
                  your browser's help function or support area to learn more.
                </li>
                By continuing to use the Platform, you consent to the use of
                Cookies and other device identifiers in accordance with this
                Policy. If you do not agree to the use of Electronic
                Identifiers, you should discontinue using the Platform or manage
                your Electronic Identifiers as aforesaid above
              </ul>
            </li>
          </ol>
        </li>

        <li>
          Disclosure of information: The information available with the Platform
          in relation to the User may be disclosed under the following
          circumstances:
          <ol>
            <li>
              Boxofic: The Platform may share your information among the Boxofic
              family of companies as needed for: providing you with access to
              the Platform’s services; providing customer support; making
              decisions about service improvements; content development; and for
              other purposes described in this Policy.
            </li>
            <li>
              Service Providers: The Platform uses other companies, agents or
              contractors ("Service Providers") to perform services on the
              Platform’s behalf or to assist the Platform with the provision of
              services to you. For example, the Platform engages Service
              Providers to provide advertising, security, infrastructure and IT
              services, to customize, personalize and optimize the Platform, for
              legal purposes, for payment methods, to provide customer service
              and to process and administer consumer surveys, etc. In the course
              of providing such services, these Service Providers may have
              access to your personal or other information. The Platform does
              not authorize them to use or disclose your PII except in
              connection with providing their services.
            </li>
            <li>
              Affiliates: As described above, you may have a relationship with
              one or more of the Platform’s Affiliates, in which case the
              Platform may share certain information with them in order to
              coordinate with them on providing the Platform to members and
              providing information about the availability of the Platform. For
              example, depending on what Affiliate services you use, the
              Platform may share information: (i) in order to facilitate
              Affiliate pre-paid promotions or collection of payment for the
              Platform for distribution to the Platform; (ii) with Affiliates
              who operate voice assistant platforms that allow you to interact
              with the Platform using voice commands; (iii) so that content and
              features available in the Platform can be suggested to you in the
              Affiliates user interface.
            </li>
            <li>
              Promotional offers: the Platform may offer joint promotions or
              programs that, in order for your participation, will require the
              Platform to share your information with third parties. In
              fulfilling these types of promotions, the Platform may share your
              name and other information in connection with fulfilling the
              incentive. Please note that these third parties are responsible
              for their own privacy practices.
            </li>
            <li>
              Protection of the Platform and others: The Platform and its
              service providers may disclose and otherwise use your personal and
              other information to locations inside and outside India (as
              applicable) where the Platform or they reasonably believe such
              disclosure is needed to (a) satisfy any applicable law,
              regulation, legal process, or governmental request, (b) enforce
              applicable terms of use, including investigation of potential
              violations thereof, (c) detect, prevent, or otherwise address
              illegal or suspected illegal activities (including payment fraud),
              security or technical issues, or (d) protect against harm to the
              rights, property or safety of the Platform, its users or the
              public, as required or permitted by law. The Platform will share
              personal data where necessary to establish, exercise or defend the
              Platform’s legal rights
            </li>
            <li>
              Business transfers: In connection with any reorganization,
              restructuring, merger or sale, or other transfer of assets, the
              Platform will transfer information, including PII, provided that
              the receiving party agrees to respect your PII in a manner that is
              consistent with the Platform’s Policy.
            </li>
            <li>
              Whenever in the course of sharing information the Platform
              transfers PII to other countries, the Platform will ensure that
              the PII is transferred in accordance with this Policy, with
              appropriate security measures in place and as permitted by the
              applicable laws on data protection.
            </li>
            <li>
              Social media: The user and social media subscribers and other
              individuals may engage with the Platform through third-party
              social networks (for example, Facebook, Twitter and Pinterest).
              The Platform may also allow Users to access their accounts or
              specific content through social networks. Where Users or other
              individuals engage or login through social media, the Platform
              will share personal data with and receive personal data from the
              relevant social network. Where Users or individuals engage or
              login through a social network, the Platform encourages that they
              read the relevant social network’s privacy policy.
            </li>
            <li>
              You may also choose to disclose your information in the following
              ways: (i) certain portions of the Platform may contain a tool
              which gives you the option to share information by email, text
              message and social or other sharing applications, using the
              applications on your smart device; and (ii) social plugins and
              similar technologies allow you to share information. Social
              plugins and social applications are operated by the social
              networks themselves, and are subject to their terms of use and
              privacy policies.{" "}
            </li>
          </ol>
        </li>

        <li>
          Automated decision making: The Platform may or may not use automated
          decision-making to provide the Users with the most relevant Content
          and develop the Platform in various ways. For clarity, the Platform
          does not use automated decision making to make legally significant
          decisions about the Users in relation to the Platform.
        </li>
        <li>
          The Platform uses commercially reasonable security measures,
          practices, methods and procedures, at the minimum, mandated under the
          Information Technology Act, 2000 as amended and read with Information
          Technology (Reasonable Security Practices and Sensitive Personal Data
          or Information) Rules, 2011, to safeguard and protect your information
          and data. Such measures are implemented by the Platform to protect any
          unauthorised access to and unlawful interception of PII. You accept,
          acknowledge and confirm that there is an inherent security risk of
          providing any kind of information over internet/ cellular networks and
          understand and accept that the Platform cannot guarantee the absolute
          security of your information. You agree to not hold the Platform
          responsible for any breach (threatened, alleged, or actual) of
          security or the disclosure of PII. You agree that disclosing any
          information by yourself is solely and completely at your own risk.{" "}
        </li>
        <li>
          Storage of information (if any): The information collected shall be
          stored in an electronic form, primarily, however, information may be
          stored in a physical form as well. You agree that the Platform may
          store, collect, process and use your data in locations/ jurisdictions
          outside the country of India and such data will be used in line and in
          compliance with applicable laws.
        </li>
        <li>
          Data retention: The Platform shall store your data in full compliance
          with applicable laws and until as necessary to comply with legal
          obligations, enforce the Platform’s policies and resolve complaints
          and/ or disputes. The Platform shall delete your information upon
          completion of the purpose of such information and shall be deleted in
          a secure method. For abundant clarity, deletion of your information
          shall exclude anonymous data collected through your use/ access of the
          Platform
        </li>
        <li>
          Access: As per the applicable laws, should you wish to request access
          to information collected about you, you may place such request by
          contacting the Platform at support@halliv.com. The Platform provides
          with you all information that the Platform maintains about you such as
          the contact information, payment (transaction) information upon proper
          verification of the User at the time of request of such information.
          Proper verification shall include the following information at the
          time of contacting the Platform for any kind of information: (i) Your
          physical or electronic signature; and (ii) adequate information by
          which the Platform can contact you. You may also request to correct
          the information collected about you by contacting the Grievance
          Officer (mentioned below) and/ or the Platform at support@halliv.com
          and/ or request the deletion of any information collected about you.
          Upon deletion of such information collected about you, you agree,
          acknowledge and confirm that such deletion may result in cancellation
          of your access to/ usage of the Platform and/ or certain features of
          the Platform. You agree and acknowledge that certain information
          collected about you cannot be deleted or corrected or prohibited to be
          deleted as required by any legal enforcement agency, under applicable
          laws and/ or for archival purposes.
        </li>
        <li>
          Support: If you have any queries, feedbacks or complaints regarding
          the Policy and/ or Platform, your access to the Platform, the Content,
          you may please avail the support and assistance of the support chat
          available on the Platform under ‘support’. In the event your query is
          not resolved via the support chat, you may please contact the Platform
          at support@halliv.com. If you have any information, data, complaint,
          feedback regarding any of your information/ PII, please reach out to
          the Platform at support@halliv.com. Proper verification shall include
          the following information at the time of contacting the Platform for
          any kind of information: (i) Your physical or electronic signature;
          (ii) adequate information by which the Platform can contact you; (iii)
          a statement stating that the information in the written notice is
          accurate; and (iv) your user id and the make of the device/ browser
          through which you access the Platform. Please refrain from frivolous
          claims and/ or misinformation as such notices are considered in a
          serious manner and you could bear legal consequences and/ or
          suspension/ termination of your account. Please note that such
          communication by you to the Platform shall only result in taking down
          any information upon a valid court decree from the relevant government
          organisation/ agency and upon following the applicable procedure and
          laws. The Platform reserves the right to take any action, however not
          under any obligation, in relation to such complaints at its sole
          discretion.
          <ul>
            <li>
              The User has full control over the type of communication you wish
              to receive from the Platform. You may opt out of receiving all
              kind of communication from the Platform by writing to
              support@halliv.com.
            </li>
            <li>
              For opting out of WhatsApp messages, you may use the app’s
              functionality settings or reply ‘STOP’ to the message received by
              the Platform; for opting out of emails, you may select the
              ‘Unsubscribe’ button at the bottom of the email, however, any
              email regarding essential communication like password re-set and
              payment shall continue; for opting out of any other kind of
              communication to you, you may use the device/ browser’s
              functionality settings for the same.{" "}
            </li>
          </ul>
        </li>

        <li>
          <p>
            Grievance Officer: The Grievance Officer is identified below
            pursuant to the provisions of applicable laws including but not
            limited to the Information Technology Act, 2000 and the rules
            enacted under those laws. Please find the details of the Grievance
            Officer below for any grievances related to privacy:
          </p>{" "}
          <br />
          Name: Ashish Mohan
          <br /> Email: grienvance@halliv.com <br />
          Address: D-10/2 Okhla phase 2, New delhi <br />
          Please be sure to include your details in the grievance including but
          not limited to user id, make of the device/ browser you access the
          Platform through, information so that the Platform can contact you and
          your digital or physical signature.
        </li>

        <li>
          Miscellaneous:
          <ul>
            <li>
              This Policy shall be governed by and construed in accordance with
              the laws of India and the Courts at New Delhi shall have exclusive
              jurisdiction regarding any matter arising out of or related to
              this Policy
            </li>
            <li>
              Each affiliate of the Platform is severally liable for its own
              obligations under this Policy and is not jointly liable for the
              obligations of other affiliates of the Platform. In addition, each
              affiliate of the Platform is solely responsible with respect to
              its exercise of its rights and compliance with its obligations in
              connection with the territory or territories for which it is
              responsible, as determined by the Platform in its sole discretion.
            </li>
            <li>
              If any provision of this Agreement is adjudged by a court to be
              void or unenforceable, the same shall in no way affect any other
              provision of this Agreement, or its validity or enforceability and
              the unenforceable provision shall be performed to the extent valid
              and enforceable.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};
