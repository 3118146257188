import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import * as ROUTES from "./constants/routes";
import "fa-icons";
import LoginWithGoogle from "./components/login-with-social/LoginWithGoogle";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Legalnotices } from "./pages/legalnotices";
import { Contact } from "./pages/contact";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import PreLaunchPage from "./pages/PreLaunchPage";
import { ToastContainer, toast } from "react-toastify";
import InviteUsers from "./pages/InviteUsers";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Search } from "./containers/search.jsx";
import Login from "./components/Login/Login";

import Browse from "./pages/Browse/Browse";
import Layout from "./pages/Layout/Layout";
import { Details } from "./pages/Details/Details";
import { Profile } from "./pages/Profile/Profile";
import { OrderHistory } from "./pages/OrderHistory/OrderHistory";
import { BuyTickets } from "./pages/Buy Tickets/BuyTickets";

import "react-toastify/dist/ReactToastify.css";
import Payment from "./pages/payment";
import PaymentPending from "./pages/paymentPending";
import PaymentSuccess from "./pages/paymentSuccess";
import PaymentError from "./pages/paymentError";
// import { Signup } from "./pages/signup";
import { Favourites } from "./pages/Favourites/Favourites";
import Home from "./pages/Home/Home";
import SignUp from "./components/Signup/Signup";
import { TermAndConditons } from "./pages/termAndConditons";
import { Privacy } from "./pages/privacy";
import { ForgotPassword } from "./pages/Auth/forgot-password";
import { ChangePassword } from "./pages/Auth/ChangePassword";
import { Rating } from "./components/rating/Rating";
import { WatchHistory } from "./components/Watchhistory/WatchHistory.jsx";
import { Support } from "./components/Support/Support";

import { OtpScreen } from "./pages/Auth/Otp/OtpScreen";
import { DeleteAccount } from "./pages/Profile/DeleteAccount";
import { DeleteOtpScreen } from "./pages/Profile/DeleteOtp";
import { RedirectProvider } from "./helpers/redirect_route";

export default function App() {
  const [isToastVisible, setIsToastVisible] = useState(false);
  // getting all the toast
  const getToast = (message, position, type) => {
    if (toast.isActive) {
      toast.dismiss();
    }
    // if (isToastVisible) {
    //   return;
    // }
    // setIsToastVisible(true);
    const obj = {
      item: message,
      tag: type === "success" ? "Success" : "Failure",
      className: type === "success" ? "success" : "error",
    };
    const notifications =
      JSON.parse(sessionStorage.getItem("notifications")) || [];

    if (notifications.length >= 20) {
      notifications.shift();
    }

    notifications.push(obj);
    sessionStorage.setItem("notifications", JSON.stringify(notifications));

    switch (type) {
      case "success":
        toast.success(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsToastVisible(false),
        });
        break;
      case "error":
        toast.error(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsToastVisible(false),
        });
        break;

      case "warn":
        toast.warn(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsToastVisible(false),
        });

        break;

      case "info":
        toast.info(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsToastVisible(false),
        });

        break;
      // Add more cases for other types as needed
      default:
        // Handle unknown types or provide a default behavior
        toast(message, {
          position: position,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsToastVisible(false),
        });
        break;
    }
  };
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);

  function getDeviceType() {
    if (window.innerWidth >= 1800) {
      return "tv";
    } else if (
      userAgent.includes("windows") ||
      userAgent.includes("win64") ||
      userAgent.includes("win32") ||
      // 768×1024
      userAgent.includes("macos") ||
      window.innerHeight >= 1024 ||
      window.innerWidth >= 768
    ) {
      return "laptop";
    } else {
      return "mobile";
    }
  }
  return (
    <>
      <Router>
        <ScrollToTop />
        <GoogleOAuthProvider clientId="892576251217-pii15lc8ep6p145roa5r7q1k9rjrjl9g.apps.googleusercontent.com">
          <RedirectProvider>
            <Routes>
              <Route
                path={ROUTES.SIGN_IN}
                // element={<IsUserRedirect redirectPath={ROUTES.PRELAUNCH}><Signin /></IsUserRedirect>}
                element={
                  <IsUserRedirect redirectPath={"/browse/all"}>
                    <Login getDeviceType={getDeviceType} />
                    {/* <Signin /> */}
                  </IsUserRedirect>
                }
              />
              <Route
                path={ROUTES.FORGOT_PASSWORD}
                element={<ForgotPassword getDeviceType={getDeviceType} />}
                getToast={getToast}
              />
              <Route
                path={ROUTES.CHANGE_PASSWORD}
                element={<ChangePassword getDeviceType={getDeviceType} />}
                getToast={getToast}
              />

              <Route
                path={ROUTES.SIGN_UP}
                // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH}><Signup /></IsUserRedirect>}
                element={
                  <IsUserRedirect redirectPath={"/browse/all"}>
                    <SignUp getDeviceType={getDeviceType} />
                    {/* <Signup /> */}
                  </IsUserRedirect>
                }
              />

              <Route
                path={ROUTES.LOGIN_WITH_GOOGLE}
                // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH}><LoginWithGoogle /></IsUserRedirect>}
                element={
                  <IsUserRedirect redirectPath={"/browse/all"}>
                    <LoginWithGoogle getDeviceType={getDeviceType} />
                  </IsUserRedirect>
                }
              />

              <Route
                path={ROUTES.HOME}
                // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH} exact><Home /></IsUserRedirect>}
                element={
                  <IsUserRedirect redirectPath={"/browse/all"} exact>
                    <Home getDeviceType={getDeviceType} />
                  </IsUserRedirect>
                }
              />
              <Route
                path={ROUTES.OTP}
                element={<OtpScreen getDeviceType={getDeviceType} />}
              />

              <Route path={ROUTES.LEGAL_NOTICES} element={<Legalnotices />} />

              <Route
                path={ROUTES.TERMS_OF_USE}
                element={<TermAndConditons />}
              />
              <Route path={ROUTES.PRIVACY} element={<Privacy />} />

              <Route path={ROUTES.CONTACT_US} element={<Contact />} />
              <Route
                path={"/account-deletion"}
                element={
                  <DeleteOtpScreen
                    getDeviceType={getDeviceType}
                    getToast={getToast}
                  />
                }
              />

              <Route element={<Layout getDeviceType={getDeviceType} />}>
                {/* browse */}
                <Route
                  index
                  path={ROUTES.BROWSE}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Browse
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* delete account */}
                <Route
                  path={"/profile/delete-account"}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <DeleteAccount
                        getDeviceType={getDeviceType}
                        getToast={getToast}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* search */}
                <Route
                  index
                  path={ROUTES.SEARCH}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Search
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* description page */}
                <Route
                  index
                  path={ROUTES.DESCRIPTION}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Details
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* prelaunch */}
                <Route
                  index
                  path={ROUTES.PRELAUNCH}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <PreLaunchPage getDeviceType={getDeviceType} />
                      //{" "}
                    </ProtectedRoute>
                  }
                />
                {/* invite user */}
                <Route
                  path={ROUTES.INVITE_USERS}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <InviteUsers
                        getDeviceType={getDeviceType}
                        getToast={getToast}
                      />{" "}
                    </ProtectedRoute>
                  }
                />

                {/* profile */}
                <Route
                  index
                  path={ROUTES.PROFILE}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Profile
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* favourite */}
                <Route
                  index
                  path={ROUTES.FAVOURITES}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Favourites
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* order history */}
                <Route
                  index
                  path={ROUTES.ORDER_HISTORY}
                  element={
                    // <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                    <OrderHistory
                      getToast={getToast}
                      getDeviceType={getDeviceType}
                    />
                    // </ProtectedRoute>
                  }
                />
                {/* buy tickets */}
                <Route
                  index
                  path={ROUTES.BUY_TICKET}
                  element={
                    // <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                    <BuyTickets getDeviceType={getDeviceType} />
                    // </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.PAYEMENT}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Payment getDeviceType={getDeviceType} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.PAYMENT_PENDING}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <PaymentPending getDeviceType={getDeviceType} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  index
                  path={ROUTES.PAYMENT_ERROR}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <PaymentError getDeviceType={getDeviceType} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.RATING}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Rating
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* watch histroy */}
                <Route
                  path={ROUTES.WATCH_HISTORY}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <WatchHistory
                        getToast={getToast}
                        getDeviceType={getDeviceType}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.SUPPORT}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Support
                        getDeviceType={getDeviceType}
                        getToast={getToast}
                      />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path={ROUTES.PAYMENT_SUCCESS}
                element={
                  <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                    <PaymentSuccess
                      getToast={getToast}
                      getDeviceType={getDeviceType}
                    />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </RedirectProvider>
        </GoogleOAuthProvider>
      </Router>
      <ToastContainer stacked />
    </>
  );
}
