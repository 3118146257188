import { Navigate, useLocation } from "react-router-dom";
import { useAuthListener } from "../hooks";
import { useRedirect } from "./redirect_route";

export function IsUserRedirect({ redirectPath, children }) {
  const user = useAuthListener();
  if (!user) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
}

export function ProtectedRoute({ children }) {
  const user = useAuthListener();
  const location = useLocation();
  const { setRedirectPath } = useRedirect();

  if (!user) {
    console.log("loaction path name", location.pathname);
    setRedirectPath(location.pathname);
    return <Navigate to={"/signin"} replace />;
  }

  return children;
}
