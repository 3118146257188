import React, { useState } from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import googleLogo from "../../images/new-google-icon.svg";
import axios from "axios";
import { useNavigate } from "react-router";
import "../../pages/styles/signin.css";
import { Modal } from "react-bootstrap";
import { baseUrl } from "../../Api-config/auth";

const LoginWithGoogle = ({ task, show, setShow, message, setMessage }) => {
  const [success, setSuccess] = useState(false);
  // const navigate = useNavigate()

  const BaseUrl = baseUrl + "login-with-google";

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log("Token Response: ", tokenResponse);
      console.log("Token Response access_toke: ", tokenResponse.access_token);
      if (task === "SIGNUP") {
        // checking weather the request is from SIGNUP page
        axios
          .get(
            `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
          ) // getting userDetails from accessToken
          .then((res) => {
            res = res.data;

            console.log("Response: ", res);
            let data = {
              firstName: res.given_name,
              lastName: res.family_name,
              email: res.email,
              image: res.picture,
              type: "viewer",
            };
            axios
              .post(baseUrl + "viewer-app/signup-with-google.v1", data)
              .then((re) => {
                setShow(true);
                setMessage(re.data.message);
              })
              .catch((err) => console.log(err));

            // let config = {
            //     method: 'post',
            //     maxBodyLength: Infinity,
            //     url: BaseUrl + 'register-user-with-google',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     data: data
            // };
            // axios.request(config)
            //     .then((res) => {
            //         console.log(res.data)
            //         if (res?.data?.status === false) {
            //             setShow1(true)
            //             setMsg(res?.data?.message)
            //         }
            //         if (res?.data?.status === true) {
            //             if (res?.data?.response_code === 200)
            //                 setSuccess(true)
            //                 setShow1(true)
            //                 setMsg("Registered Successfully")
            //                setTimeout(() => {
            //                     setShow1(false);
            //                     navigate("/login");
            //                }, 3000);
            //         }
            //     })
            //     .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err));
      } else if (task === "LOGIN") {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: BaseUrl + "login-with-google",
          headers: {
            "Content-Type": "application/json",
            accesstoken: `${tokenResponse.access_token}`,
          },
        };
        // axios.request(config)
        // .then((res)=>{
        //     console.log(res)
        //     if(res?.data?.status==true){
        //         if(res?.data?.response_code){
        //           localStorage.setItem("firstName",res?.data?.data?.name);
        //           localStorage.setItem("token",res?.data?.token)
        //             setMsg(res?.data?.message)
        //               navigate('/dashboard')
        //         }}
        //         if(res?.data?.status==false){
        //             setMsg(res?.data?.message)
        //             setShow1(true)
        //         }
        // })
        // .catch((err)=>console.log(err))
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  return (
    <div>
      {/* <GoogleOAuthProvider clientId="892576251217-pii15lc8ep6p145roa5r7q1k9rjrjl9g.apps.googleusercontent.com"> */}
      {/* <GoogleLogin
            onSuccess={credentialResponse => {
                console.log(credentialResponse);
            }}
            onError={() => {
                console.log('Login Failed');
            }}
            /> */}

      <div>
        <div className="form-group" style={{ padding: "0" }}>
          <span
            className="form-control google-signin-input"
            style={{ padding: "6px 10px" }}
          >
            <button
              className="google-logo-link"
              onClick={() => login()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className="google-logo-img" src={googleLogo} alt="Hall" />
              <span className="continue-with-google-span">
                {task === "SIGNUP"
                  ? "Sign Up with Google"
                  : "Sign In with Google"}
              </span>
            </button>
          </span>
          {/* <LoginWithGoogle /> */}
        </div>
      </div>

      {/* </GoogleOAuthProvider> */}
    </div>
  );
};

export default LoginWithGoogle;
