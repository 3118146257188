import React from "react";
import styles from "./Support.module.css";

export const TicketCategories = ({
  el,
  handleSelectCategory,
  selectedCategory,
}) => {
  return (
    <div
      className={`${styles.ticketCategories} ${
        el == selectedCategory ? styles.selectedCatgory : ""
      }`}
      onClick={() => handleSelectCategory(el)}
    >
      {el}
    </div>
  );
};
